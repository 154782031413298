import * as React from 'react';

import { daysOfWeekCap } from '../util/date-utils';
import { getFormattedHourLabel } from '../deliveryWindows/scheduled-delivery-utils';
import Alert from '@material-ui/lab/Alert';

import styles from './OrderClarifications.module.css';

const OrderClarifications = ({order}) => {

  const [etaLabel, setEtaLabel] = React.useState();

  React.useEffect(() => {
    const { fulfillment_time_slot:slot } = order || {};
    if (slot) {
      // Slot end time
      const timeLabel = getFormattedHourLabel(slot.hours.end_hour);
      // Today's day: Monday etc.
      const todayDayLabel = daysOfWeekCap[new Date().getDay()];
      // Delivery Day of week: Monday etc.
      const { year, month, day } = slot.date;
      const deliveryDate = new Date(year, month - 1, day);
      const weekDayLabel = daysOfWeekCap[deliveryDate.getDay()];
      // Use 'Today' for today
      const dayLabel = todayDayLabel === weekDayLabel ? 'Today' : `on ${weekDayLabel}`;
      setEtaLabel(`${timeLabel} ${dayLabel}`);
    }
  }, [order])

  return ( order && etaLabel
    ? <Alert severity="info" className={styles.alert}>
        <>
          <div className={styles.clarification}>
            Expect your order to arrive by <b>{etaLabel}</b> at the&nbsp;latest!
          </div>
          { order.payment_details.processor !== 'aeropay' &&
            <div className={styles.clarification}>This order can be paid by either <b>Cash OR Debit&nbsp;Card!</b></div>
          }
        </>
      </Alert>
    : null
  )
}

export default OrderClarifications;
