import { trackError } from '../analytics/tracking';

// Data we request from the Places API, skipping "formatted_address"
export const GOOGLE_PLACES_REQUESTED_DATA = ["address_components", "geometry"]

// The global map instance
let map, geocoder;
export const destroyMap = () => {
  map = null;
};

/**
 * Create a full-function google map centered on the location
 *
 * @param {string} elemId - the target div element id
 * @param {number} zoom - map zoom level
 * @param {string} formattedAddress - address, e.g. 123 Main Street, Boston...
 */
export const initSingleLocationMap = (elemId, zoom, formattedAddress) => {
  // We're centering on map pin so no center param needed
  const options = {
    zoom,
    mapTypeControl: false,
    streetViewControl: false,
    scaleControl: true,
    mapId: elemId,
  };
  // Retrieve the address lat/long
  geocoder = new window.google.maps.Geocoder();
  // Configure map
  codeAddress(geocoder, map);

  function codeAddress(geocoder, map) {
    geocoder.geocode({'address':formattedAddress}, function(results, status) {
      if (status === 'OK') {
        const elem = document.getElementById(elemId);
        if (elem) {
          map = new window.google.maps.Map(
            elem,
            {
              ...options,
              center: results[0].geometry.location,
            },
          );
          // The advanced marker, positioned
          const pinBackground = new window.google.maps.marker.PinElement({
            glyphColor: '#fff',
            background: '#000',
            borderColor: '#000',
            scale: 1.25,
          });
          /* eslint-disable-next-line no-unused-vars */
          const marker = new window.google.maps.marker.AdvancedMarkerElement({
            map,
            position: results[0].geometry.location,
            title: formattedAddress,
            content: pinBackground.element,
          });
        }
      } else {
        // return error image?
        trackError(`map_load_failure_${status}`);
      }
    });
  }
};

/**
 * The parseGooglePlace method was pulled from the parse-google-place package
 * which is not a proper React package
 */
export const parseGooglePlace = (place) => {
  place = place || {};

  // map place by type
  const byType = (place.address_components || []).reduce(function (acc, data) {
    data.types.forEach(function (type) {
      acc[type] = data;
    })
    return acc;
  }, {});

  const result = {
    streetNumber: placeGet('street_number'),
    streetName: placeGet('route'),
    city: placeGet('locality') ||
      placeGet('sublocality') ||
      placeGet('sublocality_level_1') ||
      placeGet('neighborhood') ||
      placeGet('administrative_area_level_3') ||
      placeGet('administrative_area_level_2'),
    county: placeGet('administrative_area_level_2'),
    stateShort: placeGet('administrative_area_level_1', true),
    stateLong: placeGet('administrative_area_level_1'),
    countryShort: placeGet('country', true),
    countryLong: placeGet('country'),
    zipCode: placeGet('postal_code')
  };

  // remove null/undefined
  result.address = [
    result.streetNumber,
    result.streetName
  ].filter(Boolean).join(' ');

  // Added! Append latitude/longitude data
  if (place.geometry?.location) {
    const { lat, lng } = place.geometry.location;
    if (lat && lng) {
      result.geo_coordinates = {
        latitude: lat(),
        longitude: lng()
      };
    }
  }

  return result;

  // hoisted function - prefer short_name
  function placeGet(key, short) {
    if (!(key in byType)) return '';
    return short ? byType[key].short_name : byType[key].long_name;
  };
};

// Create a street address string from a Zyp Run location object
export const getFormattedAddress = (location) => {
  if (location && location.street_address) {
    const { street_address, apartment_number, city, state, zip_code } = location;
    const apartment = apartment_number ? ` ${apartment_number}`:'';
    return `${street_address}${apartment}, ${city}, ${state}, ${zip_code}`;
  }
  return '';
};
