import * as React from 'react';
import { trackEvent } from '../analytics/tracking';
import { GOOGLE_PLACES_REQUESTED_DATA, parseGooglePlace } from './google-maps-utils';
import { GoogleMapsContext } from '../providers/GoogleMapsProvider';

/**
 * Handle user selection of the autocomplete suggestions
 * and parse out the address components
 */
const handlePlaceSelect = async (autoComplete, onInputChange, onSelection) => {
  if (autoComplete) {
    // Get place object from the google api
    const addressObject = autoComplete.getPlace();
    // Parse the fields we need: street address, lat/long etc.
    if (onSelection) {
      const usableLoc = parseGooglePlace(addressObject);
      // Don't update the input unless user has made a typeahead selection
      if (usableLoc.address) {
        onInputChange(`${usableLoc.address}, ${usableLoc.city}, ${usableLoc.stateShort} ${usableLoc.zipCode}`);
        onSelection(usableLoc);
        trackEvent('autocomplete_address_select');
      }
    }
  }
};

// The AutoComplete instance reference
let autoComplete;

// Don't submit any forms...
const handleEnterKey = (event) => {
  if (event.key === 'Enter') {
    event.preventDefault();
  }
}

/**
 * The initial AutoComplete Widget implementation was double-requesting.
 * This hook was created to reduce fees.
 *
 * Configure the Google Places AutoComplete widget for US address lookup
 * Make sure instance is destroyed on component unmount
 *
 * DEPRECATED: use SearchLocationAutoComplete
 */
const useConfiguredAutoComplete = (inputRef, onInputChange, onSelection) => {

  const { mapsAPILoaded } = React.useContext(GoogleMapsContext);

  React.useEffect(() => {
    const inputElem = inputRef.current;
    // NOTE: If multiple instances are created the getPlace() call will return undefined
    if (mapsAPILoaded) {
      autoComplete = new window.google.maps.places.Autocomplete(
        inputRef.current,
        {
          types: [("geocode")],
          componentRestrictions: { country: "us" },
          fields: GOOGLE_PLACES_REQUESTED_DATA, // Data we want: Address, GeoCoords
        }
      );
      // add a listener to handle when a place is selected
      autoComplete.addListener("place_changed", () =>
        handlePlaceSelect(autoComplete, onInputChange, onSelection)
      );
      inputElem?.addEventListener('keydown', handleEnterKey);
    }

    // On unmount destroy AutoComplete instance
    return () => {
      autoComplete = undefined;
      inputElem?.removeEventListener('keydown', handleEnterKey);
    }
  }, [inputRef, onInputChange, onSelection, mapsAPILoaded])

  // We don't need to return anything
  // return autoComplete;
};

export default useConfiguredAutoComplete;
