import * as React from 'react';

import { trackEvent } from '../analytics/tracking';
import { NotificationsContext } from '../notifications/NotificationsProvider';
import MuiModal from '../modal/MuiModal';
import LocationOnRoundedIcon from '@material-ui/icons/LocationOnRounded';

import orderStyles from '../orders/YourOrders.module.css';
import styles from '../modal/MuiModal.module.css';

// For DEV: Place a dev order, move it to in-transit (Admin App), then drop a prod tracking url
// in for the onfleet_tracking_url below (See order in Admin App for tracking url)
const OnfleetDeliveryMap = () => {

  const { activeOrder } = React.useContext(NotificationsContext);
  const [trackingUrl, setTrackingUrl] = React.useState();
  const [showOnfleetMap, setShowOnFleetMap] = React.useState();

  React.useEffect(() => {
    const {
      status,
      show_in_transit_status_to_customer,
      onfleet_tracking_url,   // ="https://onf.lt/6ada1a6fe9"
    } = activeOrder || {};

    if (activeOrder &&
        onfleet_tracking_url &&
        (status === 'in-transit' ||
         show_in_transit_status_to_customer === true)) {
      setTrackingUrl(onfleet_tracking_url);
    } else {
      setTrackingUrl(undefined);
    }
  }, [activeOrder]);

  const showMapClick = () => {
    setShowOnFleetMap(true);
    trackEvent('show_onfleet_map_click');
  }

  return ( trackingUrl
    ? <>
        <div className={orderStyles.orderSectionHdr}>Out for Delivery</div>
        <div className={styles.onfleetMapCallout} onClick={showMapClick}>
          <div className={styles.onfleetCalloutText} >
            <LocationOnRoundedIcon className={styles.pinIcon} />
            Track Your current delivery
          </div>
          <div className={styles.imgWrap}>
            <img alt="" className={styles.mapThumb} src="/img/onfleet_map_thumb.jpg" height="150" />
          </div>
        </div>

        <MuiModal
          className={styles.onfleetModal}
          open={showOnfleetMap}
          setOpen={setShowOnFleetMap}>
          <iframe
            title="Zyp Run Live Delivery Map"
            className={styles.onfleetIframe}
            width="100%"
            src={trackingUrl} />
        </MuiModal>
      </>
    : null
  )
}

export default OnfleetDeliveryMap;
