import * as React from 'react';
import { trackEvent, trackingString } from '../analytics/tracking';
import { DISPLAY_NEW_ITEMS_MAX } from './useNewlyAddedProducts';
import ProductCarousel from '../home/ProductCarousel';

import styles from './NewProducts.module.css';

const NewThisSessionCarousel = ({
  categoryName,
  newlyAddedSinceDate,
  newlyAddedByCategory
}) => {

  const [newProducts, setNewProducts] = React.useState();

  // If there's too many new items we suppress display
  // since the data is less helpful
  React.useEffect(() => {
    if (categoryName && newlyAddedByCategory?.size > 0) {
      const newItems = newlyAddedByCategory.get(categoryName);
      if ( newItems && newItems.length <= DISPLAY_NEW_ITEMS_MAX) {
        setNewProducts(newItems);
        trackEvent(`new_itms_${trackingString(categoryName)}_csl_display`);
      } else {
        setNewProducts(undefined);
        if ( newItems?.length > DISPLAY_NEW_ITEMS_MAX) {
          trackEvent(`new_itms_${trackingString(categoryName)}_csl_suppressed`);
        }
      }
    }
  }, [categoryName, newlyAddedByCategory])

  return ( categoryName && newlyAddedSinceDate && newProducts?.length
    ? <div className={styles.newProductsWrapper}>
        <div id="newly_added" className={styles.newProductsScrollTarget}></div>
        <ProductCarousel
          carouselTitle={`New & Restocked ${categoryName}`}
          carouselSubTitle={`(Since your visit on: ${newlyAddedSinceDate})`}
          categoryCount={newProducts.length}
          carouselProducts={newProducts}
          trackingPrefix="new_itms" />
      </div>
    : null
  )
}

export default NewThisSessionCarousel;
