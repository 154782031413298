import * as React from 'react';

import config from '../../config';
import { trackEvent } from '../analytics/tracking';
import { usePlacesWidget } from "react-google-autocomplete";
import { GOOGLE_PLACES_REQUESTED_DATA, parseGooglePlace } from './google-maps-utils';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';

// Places autocomplete using the react-google-autocomplete package to
// debounce requests which inflate our bill
const SearchLocationAutoComplete = ({
  /* Careful! there is a zero-width space within "address" to prevent Chrome auto-complete */
  placeholder='Enter your a​ddress',
  onSelection=()=>{},
  disableAutoFocus
}) => {

  const [inputVal, setInputVal] = React.useState("");

  const { ref, /* autocompleteRef */ } = usePlacesWidget({
    apiKey: config.GOOGLE_MAPS_KEY,
    onPlaceSelected: response => {
      const usableLoc = parseGooglePlace(response);
      setInputVal(`${usableLoc.address}, ${usableLoc.city}, ${usableLoc.stateShort} ${usableLoc.zipCode}`);
      onSelection(usableLoc);
      trackEvent('autocomplete_address_select');
    },
    options: {
      types: [("geocode")],
      componentRestrictions: { country: "us" },
      fields: GOOGLE_PLACES_REQUESTED_DATA,
    }
  });

  return (
    <TextField
      autoFocus={!disableAutoFocus}
      autoComplete='off'
      inputRef={ref}
      label={placeholder}
      placeholder={placeholder}
      variant="filled"
      onChange={event => setInputVal(event.target.value)}
      onKeyDown={event => {
        if (event.key === 'Enter') {
          setInputVal(event.target.value);
        }
      }}
      value={inputVal}
    />
  );
}

SearchLocationAutoComplete.propTypes = {
  placeholder: PropTypes.string,
  onSelection: PropTypes.func.isRequired,
  disableAutoFocus: PropTypes.bool
}

export default SearchLocationAutoComplete;
