import React from 'react';

import { UserContext } from '../providers/UserProvider';
import { getLatestOrderTimeMS, slotDiscountForLocation } from './timeslot-utils';
import StandardTimeSlot from './StandardTimeSlot';
import PriorityTimeSlot from './PriorityTimeSlot';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import RemoveRoundedIcon from '@material-ui/icons/RemoveRounded';
import PropTypes from 'prop-types';

import styles from './SelectTimeSlot.module.css';

const SelectTimeSlot = ({
  dayIdx=0,
  slotIdx=0,
  displayDayIdx,
  timeSlots=[],
  currentTimeMS,
  handleSelect
}) => {

  const toggleIconStyle = {
    verticalAlign: -6,
    fontSize: 24
  };

  // Return selection to DisplayDeliverySlots
  const onSelect = (event) => {
    const slotIdx = parseInt(event.target.value, 10);
    handleSelect(slotIdx);
  }

  // Toggle unavailable slots
  const [showAllClass, setShowAllClass] = React.useState('');
  const toggleShowAll = React.useCallback(() => {
    setShowAllClass(showAllClass === styles.showAll
      ? ''
      : styles.showAll
    );
  }, [showAllClass]);

  const { location } = React.useContext(UserContext);
  const { zip_code:user_zip_code } = location || {};

  return (
    <div className={showAllClass}>
      { timeSlots.length
        ? <>
            { displayDayIdx === 0 &&
              <>
                {/* We have expired slots */}
                { timeSlots[0].isExpired &&
                  <div className={styles.toggleBtn} onClick={toggleShowAll}>
                    <div className={styles.showMsg}>
                      Show unavailable delivery windows <AddRoundedIcon style={toggleIconStyle} />
                    </div>
                    <div className={styles.hideMsg}>
                      Hide unavailable delivery windows <RemoveRoundedIcon style={toggleIconStyle} />
                    </div>
                  </div>
                }
                {/* We have no same-day slots */}
                { timeSlots[timeSlots.length - 1].isExpired
                  ? <div className={styles.noWindowsMsg}>
                      Sorry, there are no same-day delivery windows&nbsp;available...
                    </div>
                  : null
                }
              </>
            }
            { timeSlots.map((slot,idx) => {
              // Here we account for the day offset (MS) for non-today slots
              const slotCloseMS = getLatestOrderTimeMS(displayDayIdx, slot);
              const isExcludedZipCode = slot.exclude_zip_codes?.includes(user_zip_code);
              const discountCents = slotDiscountForLocation(slot.incentive, location);

              const radioId = `day_${displayDayIdx}_slot_${idx}`;
              const isChecked = displayDayIdx === dayIdx && idx === slotIdx;
              const isDisabled = slot.isExpired || isExcludedZipCode;

              return ( slot.on_demand
                ? <PriorityTimeSlot
                    key={radioId}
                    idx={idx}
                    slot={slot}
                    radioId={radioId}
                    isDisabled={isDisabled}
                    isChecked={isChecked}
                    currentTimeMS={currentTimeMS}
                    onSelect={onSelect} />
                : <StandardTimeSlot
                    key={radioId}
                    idx={idx}
                    radioId={radioId}
                    slot={slot}
                    isDisabled={isDisabled}
                    isChecked={isChecked}
                    slotCloseMS={slotCloseMS}
                    discountCents={discountCents}
                    isExcludedZipCode={isExcludedZipCode}
                    onSelect={onSelect} />
              )
            })}
          </>
        : null
      }
    </div>
  )
}

SelectTimeSlot.propTypes = {
  dayIdx: PropTypes.number.isRequired,
  slotIdx: PropTypes.number.isRequired,
  displayDayIdx: PropTypes.number.isRequired,
  timeSlots: PropTypes.array.isRequired,
  currentTimeMS: PropTypes.number.isRequired,
  handleSelect: PropTypes.func.isRequired
};

export default SelectTimeSlot;
