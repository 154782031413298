import React from 'react';

import { trackUserNavigation } from '../analytics/tracking';
import { DELIVER_TO_BAR_THRESHOLD } from '../address/DeliverTo';
import { navigate } from '@reach/router';

// Adds data-route attribute to body for Header/Footer styling
export const addRouteToBody = () => {
  const pathItems = window.location.pathname.split('/');
  const route = pathItems.length > 1 ? pathItems[1] || 'home' : 'home';
  document.body.setAttribute('data-route', route);
};

// Capture current page for back/continue
export const navigateTopWithState = (path, state) => {
  navigateTop(path, { state });
};

// Adds scrollTop to native navigate
export const navigateTop = (path, options={}) => {
  // { replace, state } are the real router options
  const {
    scrollYTo=0
  } = options;

  // options: { replace: true, state: { ... }}
  navigate(path, options).then(() => {
    if (scrollYTo > 0) {
      window.scrollTo(0,scrollYTo);
    }
  });

  // Used for footer icon state?
  addRouteToBody(path);
  // Log when user has navigated beyond first view
  trackUserNavigation();
}

// Used for category filters when we don't want to scroll-to-top
// and want to scroll to a specific target
export const getScrollPixels = (elemRef, addPixels=0) => {
  const navAdjustment = window.innerWidth < DELIVER_TO_BAR_THRESHOLD ? 0 : 48;
  return elemRef.current
    ? elemRef.current.offsetTop - navAdjustment + addPixels
    : 0;
}

/**
 * The purpose of this component is to expose a deep link to Google that
 * is not required for normal App navigation.  For example, if we want to
 * show product content in a modal but also supply a deep link for SEO.
 *
 * @param {string} deepLink - The link we want to expose to Google
 * @param {object} styleClass - The CSS class
 * @param {string} listKey - Unique key used for rendering links in a list
 * @param {function} handleClick - The function that will handle the user's click and navigate appropriately
 * @param {*} children = Any wrapped elements
 */
export const SEOLinkWrapper = ({deepLink, styleClass, listKey, handleClick, children}) => {
  const onClick = (event) => {
    // disregard the SEO deep link
    event.preventDefault();
    // navigate via the click handler
    if (handleClick) {
      handleClick();
    }
  }
  const linkStyle = { textDecoration:'none'}
  // a key is required when rendering a list of links/linked content
  return ( listKey
    ? <a href={deepLink} key={listKey} className={styleClass} style={linkStyle} onClick={onClick}>
        {children}
      </a>
    : <a href={deepLink} className={styleClass} style={linkStyle} onClick={onClick}>
        {children}
      </a>
  )
};
