import * as React from "react";

import { devLog } from "../util/util";
import { isToday } from "../util/util";
import { todayLower, tomorrowLower, currentHourBoston } from "../util/date-utils";
import { UserContext } from "../providers/UserProvider";
import { DeliveryOptionsContext } from "../providers/DeliveryOptionsProvider";
import useCustomerOrders, { ORDER_STATUS, orderCountsAgainstDate } from '../orders/useCustomerOrders';
import useCustomerCredits from '../refer/useCustomerCredits';
import useOrderStatusInterval from "./useOrderStatusInterval";
import usePageVisibility from "../util/usePageVisibility";

// Format for ETA: 3:35pm
const timeFormatOptions = {
  hour: 'numeric',
  minute: 'numeric',
  hour12: true
};
// For testing, creditToUse: testCredit,
export const testCredit = {
  value: {
    usa_cents: 2500
  },
  reason: 'referral'
};

// 'after-hours' is NOT a real order status. Between 10pm and 11am
export const AFTER_HOURS_STATUS = 'after-hours';

export const NotificationsContext = React.createContext({});

const NotificationsProvider = ({children}) => {

  const { user } = React.useContext(UserContext);
  const { timeSlotsByDay } = React.useContext(DeliveryOptionsContext);

  // e.g. 3:35pm
  const [etaLabel, setEtaLabel] = React.useState();

  // Order history to determine credits
  const { activeOrders, priorOrders, hasCurrentDayOrders, refetch:refetchOrders } = useCustomerOrders(user);

  // Be aware of any credits the user has
  const { activeCredits, activeCreditCount, creditToUse, loading:creditsLoading, refetch:refetchCredits } = useCustomerCredits(user);

  // Determine if outside biz hours: "store is closed"
  // Determine service open hour, if after hours, use tomorrow's value
  const [storeIsClosed, setStoreisClosed] = React.useState();
  const [afterHoursLabel, setAfterHoursLabel] = React.useState();
  React.useEffect(() => {
    if (timeSlotsByDay) {
      const currentHour = currentHourBoston();
      const todaySlots = timeSlotsByDay[todayLower()];
      const tomorrowSlots = timeSlotsByDay[tomorrowLower()];

      const openHour = todaySlots[0].hours.start_hour;
      const closeHour = todaySlots[todaySlots.length - 1].hours.end_hour;
      const openHourTomorrow = tomorrowSlots[0].hours.start_hour;
      // NOTE: we're allowing 59 mins for late deliveries as 9:59 is not > 9 below.
      setStoreisClosed(currentHour < openHour || currentHour > closeHour);
      const openAt = currentHour > closeHour
        ? openHourTomorrow  // Handle 10pm to 11:59pm
        : openHour;
      // Format and handle midnight:0 and am/pm
      setAfterHoursLabel(`Open ${openAt ? openAt : '12'}${openAt < 12 ? 'am' : 'pm'}`);
    }
  }, [timeSlotsByDay])

  // TRUE when there's an active order
  const [activeOrder, setActiveOrder] = React.useState();
  const [activeOrderStatus, setActiveOrderStatus] = React.useState();
  // Display "After Hours" when we're not open
  const [activeOrderPillStatus, setActiveOrderPillStatus] = React.useState();

  // For the order progress pill: 3:35pm
  const getETALabel = React.useCallback((etaAddlSeconds, etaFromSeconds) => {
    return new Date((etaFromSeconds + etaAddlSeconds) * 1000)
                   .toLocaleString('en-US', timeFormatOptions)
                   .toLocaleLowerCase()
                   .replace(' ', '');
  }, []);

  // Update Order Status immediately on tab activate
  usePageVisibility(React.useCallback((visibilityState) => {
    if (visibilityState === "visible") {
      refetchCredits();
      if (hasCurrentDayOrders) {
        refetchOrders();
      }
    }
  }, [hasCurrentDayOrders, refetchCredits, refetchOrders]));

  /**
   * Currently our real "In-Transit" status means the
   * customer's order is NEXT for delivery.
   *
   * This is not very helpful so we look for other indications
   * that a "ready-for-pickup" order is in the car:
   *
   * 1) The order has an .onfleet_tracking_url
   * 2) The order has .show_in_transit_status_to_customer:true
   * 3) The delivery date is Today and we are 45 minutes into the timeslot
   *
   * In these cases, we display an 'in-transit' status to user
   */
  const getDerivedStatus = (order) => {
    const triggerMinutes = 45;
    // Logic is only for READY orders
    if (order?.status === ORDER_STATUS.READY_FOR_PICKUP) {
      // Delivery date: order.order_counts_against_date: "2023-04-27"
      const deliveryDate = orderCountsAgainstDate(order.order_counts_against_date);
      if (isToday(deliveryDate)) {
        // Detect order is actually in-transit
        if (order.onfleet_tracking_url || order.show_in_transit_status_to_customer) {
          return ORDER_STATUS.IN_TRANSIT;
        } else {
          const { start_hour, start_minute } = order.fulfillment_time_slot.hours;
          deliveryDate.setHours(start_hour);
          deliveryDate.setMinutes(start_minute);
          const inTransitTrigger = new Date(deliveryDate.getTime() + (triggerMinutes * 60000));
          const now = new Date();
          if (inTransitTrigger < now) {
            return ORDER_STATUS.IN_TRANSIT;
          }
        }
      }
    }
    return order?.status || undefined;
  }

  // Check for an active SAME-DAY order
  React.useEffect(() => {
    if (hasCurrentDayOrders) {
      // This will trigger footer status pill
      const currentOrder = activeOrders?.length ? activeOrders[0] : undefined;
      setActiveOrder(currentOrder);
      setActiveOrderStatus(currentOrder?.status);
      // If the hour is before 11am we'll be closed so show the fallback "Open 11am" pill
      if (storeIsClosed) {
        // Used only for fetch interval
        setActiveOrderPillStatus(AFTER_HOURS_STATUS);
      } else {
        // Here we may move 'ready' to 'in-transit' due to onfleet limitations
        setActiveOrderPillStatus(getDerivedStatus(currentOrder));
        // Set the ETA label if data is available
        const { eta_seconds, eta_updated_at } = currentOrder || {};
        if (eta_seconds && eta_updated_at) {
          setEtaLabel(getETALabel(eta_seconds, eta_updated_at));
        }
      }
    } else {
      // Unset active order details on order completion/cancel
      setActiveOrder(undefined);
      setEtaLabel(undefined);
    }
  },[hasCurrentDayOrders, activeOrders, getETALabel, storeIsClosed])

  /* Order fetch interval runs only when we have an activeOrder */
  useOrderStatusInterval(() => {
    refetchOrders();
    devLog('Checking current order status');
  }, hasCurrentDayOrders, activeOrderStatus);

  return (
    <NotificationsContext.Provider value={{
      activeOrder,
      activeOrderId: activeOrder?.id,
      activeOrderPillStatus, // For display, incl. "Open 11am"
      activeOrderStatus,     // NOT for display! as we tweak 'ready' => 'in-transit'
      activeOrderEtaLabel: etaLabel,  // "3:35pm"
      priorOrders,
      refetchOrders,
      activeCredits,
      activeCreditCount,
      activeCreditValueCents: creditToUse?.value.usa_cents,
      creditToUse,
      creditsLoading,
      refetchCredits,
      storeIsClosed,
      afterHoursLabel,  // Open 10am
    }}>
      {children}
    </NotificationsContext.Provider>
  );
};

export default NotificationsProvider;
