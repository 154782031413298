import * as React from 'react';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { ExpandMoreOutlined } from '@material-ui/icons';
import Header, { HeaderSize } from '../styleguide/Header';
import Alert from '@material-ui/lab/Alert';
import DisplayCartedBundleProducts, { bundleDealShape } from './DisplayCartedBundleProducts';
import DisplayCartedDealProducts from './DisplayCartedDealProducts';
import PropTypes from 'prop-types';

import styles from '../common/SimpleAccordion.module.css';

/**
 * Display deal products at checkout
 * (that prevent discount code usage)
 */
const CartedDealsAccordion = ({
  bundleDealData,
  superDealItems,
  isCredits,
}) => {

  // Accordion expand/collapse
  const [expanded, setExpanded] = React.useState(false);

  return (
    <Alert severity='info'>
      <div>
        { isCredits
          ? <><b>Sorry!</b> Referral Credits cannot be used on orders containing <b>Deal Products</b>.</>
          : <><b>Sorry!</b> Promo Codes cannot be used on orders containing <b>Deal Products</b>.</>
        }
      </div>
      <div className={styles.accordionWrap}>
        <Accordion elevation={0} expanded={expanded} onChange={() => setExpanded(!expanded)}>
          <AccordionSummary className={styles.accordionTitle} expandIcon={<ExpandMoreOutlined />}>
            <Header withClass={`${styles.formHeader} ${styles.accordionHeader}`}
                    size={HeaderSize.Medium_Static}>
              Show Deal Products
            </Header>
          </AccordionSummary>
          <AccordionDetails className={styles.surveyAccordionDetails}>
            <DisplayCartedBundleProducts
              bundleDealData={bundleDealData} />
            <DisplayCartedDealProducts
              superDealProducts={superDealItems} />
          </AccordionDetails>
        </Accordion>
      </div>
    </Alert>
  );
}

CartedDealsAccordion.propTypes = {
  bundleDealData: PropTypes.objectOf(PropTypes.shape(bundleDealShape)),
  superDealItems: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    display_info: PropTypes.object.isRequired,
  })),
  isCredits: PropTypes.bool,
}

export default CartedDealsAccordion;
