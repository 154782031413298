import * as React from 'react';
import { Modal } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import CloseModalIcon from '../common/CloseModalIcon';
import PropTypes from 'prop-types';

import styles from './ModalAlert.module.css';

const ModalAlert = ({
  open=false,
  setOpen,
  severity="warning",
  children,
}) => (
  <Modal
    open={open}
    BackdropProps={{style: {backgroundColor: 'rgba(0,0,0,.35)'}}}>
    <Alert severity={severity} className={styles.modalAlert}>
      <div className={styles.modalAlertContent}>
        <CloseModalIcon styles={styles} height={16} setOpen={setOpen} />
        {children}
        <div className={styles.closeLabel} onClick={() => setOpen(false)}>
          Close
        </div>
      </div>
    </Alert>
  </Modal>
)

ModalAlert.propTyes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
  severity: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object,PropTypes.array]).isRequired,
}

export default ModalAlert;
