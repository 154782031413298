import * as React from 'react';
import { Modal } from '@material-ui/core';
import CloseModalIcon from '../common/CloseModalIcon';
import PropTypes from 'prop-types';

import styles from './MuiModal.module.css';

const MuiModal = ({
  open=false,
  setOpen,
  className='',
  children,
}) => (
  <Modal
    open={open}
    BackdropProps={{style: {backgroundColor: 'rgba(0,0,0,.35)'}}}>
    <div className={`${styles.muiModal} ${className}`}>
      <CloseModalIcon styles={styles} height={16} setOpen={setOpen} />
      {children}
      <div className={styles.closeLabel} onClick={() => setOpen(false)}>
        Close
      </div>
    </div>
  </Modal>
)

MuiModal.propTyes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object,PropTypes.array]).isRequired,
}

export default MuiModal;
