import * as React from 'react';

import { dollarDisplay } from '../providers/OrderPricingProvider';
import { getShortenedPriceDisplay } from '../products/product-utils';
import { CartItemsContext } from '../checkout/CartItemsProvider';
import BundlePricingTooltip from './BundlePricingTooltip';
import usePricingDetailsForModalStyles from '../products/usePricingDetailsForModalStyles';
import PropTypes from 'prop-types';

/**
 * Display bulk price teaser and tooltip
 */
const BulkPriceDetailsForModal = ({
  product,
}) => {

  const classes = usePricingDetailsForModalStyles();

  const { bundlePricingMap } = React.useContext(CartItemsContext);
  const [minQty, setMinQty] = React.useState();
  const [minQtyPrice, setMinQtyPrice] = React.useState();
  const [incrementalBundlePrice, setIncrementalBundlePrice] = React.useState();

  React.useEffect(() => {
    if (product?.bundle_deal_data) {
      const { bundle_deal_data } = product;
      const minDeal = [...bundle_deal_data.values].sort((a,b) => b.quantity - a.quantity)
                                             .slice(-1).pop();
      setMinQty(minDeal.quantity);
      setMinQtyPrice(getShortenedPriceDisplay(minDeal.unit_price * minDeal.quantity, true));

      if (bundlePricingMap) {
        const incrementalPriceCents = bundlePricingMap.get(product.bundle_deal_data.id);
        // Space is tight, promote whole dollar offers only
        setIncrementalBundlePrice(
          incrementalPriceCents && incrementalPriceCents % 100 === 0
          ? dollarDisplay(incrementalPriceCents, true)
          : undefined);
      }
    }
  }, [product, bundlePricingMap])

  return ( minQty
    ? <div className={classes.grid}>
        <div className={classes.tipWrap}>
          <BundlePricingTooltip
            cost_usa_cents={product.display_info.cost_usa_cents}
            bundle_deal_data={product.bundle_deal_data}
            inModal={true} />
        </div>
        <div className={classes.discountedPrice}>
          { incrementalBundlePrice
            ? `In your cart: ${incrementalBundlePrice} each!`
            : `Buy ${minQty} for ${minQtyPrice}!`
          }
        </div>
      </div>
    : null
  )
}

BulkPriceDetailsForModal.propTypes = {
  product: PropTypes.object.isRequired,
}

export default  BulkPriceDetailsForModal;
