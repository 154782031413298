import React from 'react';

import { devLog } from '../util/util';
import { trackEvent } from '../analytics/tracking';
import useInterval from '../util/useInterval';
import { UserContext } from '../providers/UserProvider';
import { DispensaryContext } from '../dispensary/DispensaryProvider';
import { getNextAvailableSlot, slotDiscountForLocation } from './timeslot-utils';
import { DiscountCreditAndFeeContext } from '../providers/DiscountCreditAndFeeProvider';
import useCurrentTimeSlots from './useCurrentTimeSlots';
import { CurrentTimeContext } from '../timeSlots/CurrentTimeProvider';
import { daysOfWeek } from '../util/date-utils';
import { getDeliveryWindowLabel, getPriorityWindowLabel } from '../deliveryWindows/scheduled-delivery-utils';
import { getOrderDate } from './timeslot-utils';
import SelectDayCarousel from './SelectDayCarousel';
import SelectTimeSlot from './SelectTimeSlot';
import SlotChangeModalAlert from './SlotChangeModalAlert';
import Spinner from '../common/Spinner';

import PropTypes from 'prop-types';

import styles from './SelectTimeSlot.module.css';

// export const CHECKOUT_REFETCH_INTERVAL_MS = 10000; // 10 seconds
export const CHECKOUT_REFETCH_INTERVAL_MS = 300000; // 5 mins

const DisplayDeliverySlots = ({
  selectedDeliverySlot,
  setSelectedDeliverySlot,
}) => {

  const { location={} } = React.useContext(UserContext);
  const { refetchDispensary } = React.useContext(DispensaryContext);
  const { setWindowDiscountCents, setPriorityDeliveryFeeCents } = React.useContext(DiscountCreditAndFeeContext);

  // Time to use when deterimining what to display
  const currentTimeMS = React.useContext(CurrentTimeContext);
  const dayOfWeek = new Date(currentTimeMS).getDay();

  // Valid timeSlots to display
  const { defaultSlot, defaultDay, displayTimeSlots } = useCurrentTimeSlots();

  // The indexes used to build the timeslot JSON and pass to parent
  const [dayIdx, setDayIdx] = React.useState(0);
  const [slotIdx, setSlotIdx] = React.useState();
  // The current day (tab) we're viewing
  const [displayDayIdx, setDisplayDayIdx] = React.useState();

  // Set the default/updated selection in parent state.
  const updateSelectedSlot = React.useCallback((slotIdx, dayIdx) => {
    const timeSlots = displayTimeSlots[dayIdx];
    if (timeSlots) {
      const {
        hours,
        allows_orders_up_until_minutes_before_end,
        incentive,
        on_demand,
      } = timeSlots[slotIdx];
      // set date for calcing date plus future offset
      const orderDate = new Date();
      orderDate.setDate(orderDate.getDate() + dayIdx);
      const date = {
        month: orderDate.getMonth() + 1,
        day: orderDate.getDate(),
        year: orderDate.getYear() + 1900
      };
      // NOTE: This is the Slot Dto we submit!
      // TODO: Ideally this transform would be done later
      // as doing it here makes the selected slot unpredictable to other
      // consumers
      setSelectedDeliverySlot({
        dayIdx,
        slotIdx,
        date,
        hours,
        allows_orders_up_until_minutes_before_end,
        incentive,
        on_demand,
      });
      const usa_cents_discount = slotDiscountForLocation(incentive, location);
      setWindowDiscountCents(usa_cents_discount);
      // Priority delivery fee
      setPriorityDeliveryFeeCents(on_demand ? on_demand.fee_cents : 0);
    }
  }, [displayTimeSlots, setSelectedDeliverySlot, setWindowDiscountCents,
      setPriorityDeliveryFeeCents, location]);

  const handleDayClick = (dayIdx) => {
    trackEvent(`select_day_checkout_click`);
    setDisplayDayIdx(dayIdx);
  }

  const handleSlotSelect = (slotIdx) => {
    // update UI
    setDayIdx(displayDayIdx);
    setSlotIdx(slotIdx);
    updateSelectedSlot(slotIdx, displayDayIdx);
  };

  const getDayLabel = (offset) => {
    if (offset === 0) {
      return 'TODAY';
    }
    const weekdayNum = (dayOfWeek + offset) % 7;
    const label = daysOfWeek[weekdayNum];
    return label.charAt(0).toUpperCase() + label.slice(1);
  };

  // Set initial default slot
  React.useEffect(() => {
    if (displayTimeSlots?.length &&
        typeof defaultSlot === "number" &&
        typeof slotIdx === "undefined") {
      // We've abused the time slot object by appending slotIdx/dayIdx data to it,
      // for UI purposes. The API will ignore this data
      // Handle component re-render
      if (selectedDeliverySlot) {
        const {
          slotIdx,
          dayIdx,
          incentive,
          on_demand,
        } = selectedDeliverySlot;
        setSlotIdx(slotIdx);
        setDayIdx(dayIdx);
        setDisplayDayIdx(dayIdx);
        const usa_cents_discount = slotDiscountForLocation(incentive, location);
        setWindowDiscountCents(usa_cents_discount);
        // Priority delivery fee
        setPriorityDeliveryFeeCents(on_demand ? on_demand.fee_cents : 0);
      } else if (typeof defaultSlot === 'number') {
        setSlotIdx(defaultSlot);
        setDayIdx(defaultDay);
        setDisplayDayIdx(defaultDay);
        updateSelectedSlot(defaultSlot, defaultDay);
      }
    }
  }, [defaultSlot, defaultDay, selectedDeliverySlot, updateSelectedSlot,
      slotIdx, displayTimeSlots, setWindowDiscountCents,
      setPriorityDeliveryFeeCents, location]);

  // Force delivery window update when current selection
  // is expired/disabled via data refresh
  const handleSlotRefresh = React.useCallback((updatedSlot) => {
    const [dayIdx, slotIdx] = updatedSlot;
    if (typeof dayIdx === 'number' &&
        typeof slotIdx === 'number') {
      setSlotIdx(slotIdx);
      setDayIdx(dayIdx);
      setDisplayDayIdx(dayIdx);
      updateSelectedSlot(slotIdx, dayIdx);
    }
  }, [updateSelectedSlot]);

  // Monitor selected slot and handle expiration/disable
  const [showSlotChangeModal, setShowSlotChangeModal] = React.useState();
  React.useEffect(() => {
    if (typeof slotIdx === "number" &&
        displayTimeSlots.length &&
        displayTimeSlots[dayIdx][slotIdx].isExpired) {
        // Get the next available delivery window
        const { zip_code } = location || {};
        const updatedSlot = getNextAvailableSlot(
          displayTimeSlots,
          currentTimeMS,
          zip_code
        );
        handleSlotRefresh(updatedSlot);
        setShowSlotChangeModal(true);
        trackEvent('slot_update_modal_display');
    }
  }, [ dayIdx, slotIdx, displayTimeSlots,
       currentTimeMS, location, handleSlotRefresh])

  // Dispensary data includes PrioritySlot enable/disable
  useInterval(() => {
    // Update priority delivery enabled/disabled, etc.
    devLog('Refetch dispensary data every 5 minutes');
    refetchDispensary();
    // TODO: refresh slots
    // refetchCurrentSlots();
  }, CHECKOUT_REFETCH_INTERVAL_MS);

  return (
    <div className={styles.timeslotWrap}>
      { typeof slotIdx === 'number' &&
        typeof displayDayIdx === 'number' &&
        displayTimeSlots.length > 0
          ? <>
              <div className={styles.selectedSlotLabel}>
                { displayTimeSlots[dayIdx][slotIdx].on_demand
                  ? <span>{`${getDayLabel(dayIdx)}, ${getOrderDate(dayIdx, currentTimeMS)}, ${getPriorityWindowLabel(displayTimeSlots[dayIdx][slotIdx].hours)}`}</span>
                  : <span>{`${getDayLabel(dayIdx)}, ${getOrderDate(dayIdx, currentTimeMS)}, ${getDeliveryWindowLabel(displayTimeSlots[dayIdx][slotIdx].hours) }`}</span>
                }
              </div>

              <SelectDayCarousel
                displayDayIdx={displayDayIdx}
                displayDayLabelIdx={dayOfWeek}
                timeSlotsByDay={displayTimeSlots}
                currentTimeMS={currentTimeMS}
                handleDayClick={handleDayClick} />

              <SelectTimeSlot
                dayIdx={dayIdx}
                slotIdx={slotIdx}
                displayDayIdx={displayDayIdx}
                timeSlots={displayTimeSlots[displayDayIdx]}
                currentTimeMS={currentTimeMS}
                handleSelect={handleSlotSelect} />

              {/* Alert user when updates force a delivery window change */}
              <SlotChangeModalAlert
                open={showSlotChangeModal}
                setOpen={setShowSlotChangeModal}
                timeSlots={displayTimeSlots[dayIdx]}
                slotIdx={slotIdx}
                styles={styles} />
            </>
          : <Spinner />
      }
    </div>
  )
}

DisplayDeliverySlots.propTypes = {
  selectedDeliverySlot: PropTypes.object,
  setSelectedDeliverySlot: PropTypes.func.isRequired,
};

export default DisplayDeliverySlots;
