import * as React from 'react';

import { dollarDisplay } from '../providers/OrderPricingProvider';
import { getShortenedPriceDisplay } from '../products/product-utils';
import { CartItemsContext } from '../checkout/CartItemsProvider';
import BundlePricingTooltip from './BundlePricingTooltip';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  grid: {
    display:'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    paddingBottom: 2,
  },
  discountedPrice: {
    color: 'var(--zrTextRed)',
    fontStyle: 'italic',
    fontWeight: 600,
  },
});

/**
 * Display bulk price teaser and tooltip (Non-Modal Only!)
 */
const BulkPriceDetailsWithCartHint = ({
  product,
}) => {

  const classes = useStyles();

  const { bundlePricingMap } = React.useContext(CartItemsContext);
  const [minQty, setMinQty] = React.useState();
  const [minQtyPrice, setMinQtyPrice] = React.useState();
  const [incrementalBundlePrice, setIncrementalBundlePrice] = React.useState();

  React.useEffect(() => {
    if (product?.bundle_deal_data) {
      const { bundle_deal_data } = product;
      const minDeal = [...bundle_deal_data.values].sort((a,b) => b.quantity - a.quantity)
                                                  .slice(-1).pop();
      setMinQty(minDeal.quantity);
      setMinQtyPrice(getShortenedPriceDisplay(minDeal.unit_price * minDeal.quantity, true));

      if (bundlePricingMap) {
        const incrementalPriceCents = bundlePricingMap.get(product.bundle_deal_data.id);
        // Space is tight, promote whole dollar offers only
        setIncrementalBundlePrice(
          incrementalPriceCents && incrementalPriceCents % 100 === 0
          ? dollarDisplay(incrementalPriceCents, true)
          : undefined);
      } else {
        setIncrementalBundlePrice(undefined);
      }
    }
  }, [product, bundlePricingMap])

  return ( minQty
    ? <div className={classes.grid}>
        <div className={classes.discountedPrice}>
          { incrementalBundlePrice
            ? `In your cart: ${incrementalBundlePrice} each!` // Hint!
            : `Buy ${minQty} for ${minQtyPrice}!`
          }
        </div>
        <div>
          <BundlePricingTooltip
            cost_usa_cents={product.display_info.cost_usa_cents}
            bundle_deal_data={product.bundle_deal_data}
            withStyle={{marginTop:0}} />
        </div>
      </div>
    : null
  )
}

BulkPriceDetailsWithCartHint.propTypes = {
  product: PropTypes.object.isRequired,
}

export default  BulkPriceDetailsWithCartHint;
