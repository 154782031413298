import React from 'react';

import Alert from '@material-ui/lab/Alert';
import StrongText from '../common/StrongText';
import { MIN_ORDER_DEFINED_TEXT } from './MinimumOrderWarning';
import styles from '../common/Alert.module.css';

/**
 * Determine Min Order based on Zip Code (or Default Min Order)
 */
const MinimumOrderForCodeWarning = ({
  discountCode,
  minOrderDisplay
}) => {

  return ( discountCode && minOrderDisplay
    ? <Alert severity="warning" className={styles.minimumWarning}>
        <div className={styles.minimumMsg}>
          The minimum order (subtotal) for code
          <StrongText>{discountCode}</StrongText>
          is
          <StrongText>{minOrderDisplay}</StrongText>
        </div>
        <div className={styles.minimumFootNote}>
          {MIN_ORDER_DEFINED_TEXT}
        </div>
      </Alert>
    : null
  )
}

export default MinimumOrderForCodeWarning;
