import * as React from 'react';

import { ModalType, ModalContext } from '../modal/ModalProvider';
import { DispensaryContext } from '../dispensary/DispensaryProvider';
import { CartItemsContext } from '../checkout/CartItemsProvider';
import { getDeliveryWindowLabel } from '../deliveryWindows/scheduled-delivery-utils';
import { statusForDisplay } from './useCustomerOrders';
import { navigateTop } from '../routing/router-utils';
import { getPaymentLabel } from '../checkout/order-utils';
import DisplayModal from '../common/DisplayModal';
import CancelOrderButton from './CancelOrderButton';
import Button from '../styleguide/Button';
import SpinnerWrapper from '../common/SpinnerWrapper';
import OrderStatusHelp from './OrderStatusHelp';
import ProductCardForGrid, { STYLE_MODULE } from '../products/ProductCardForGrid';
import AddedToCartCheck from '../products/AddedToCartCheck';
import HelpRounded from '@material-ui/icons/HelpRounded';
import Spinner from '../common/Spinner';
import { useAnalyticsStore } from '../../App';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

import styles from './YourOrders.module.css';

const useStyles = makeStyles({
  'orderDetailHdrVal': {
    color: 'var(--zrPrimaryDark)'
  }
});

/**
 * Display single order details and provide a cancel option
 */
const OrderDetails = ({
  id,
  type,
  created_at,
  status,
  dispensary_id,
  items=[],
  costs={},
  payment_details={},
  fulfillment_time_slot={},
  enableReOrder,
  canceling,
  setCanceling,
  refetchOrders,
}) => {

  const { trackEvent } = useAnalyticsStore();
  const { displayModal } = React.useContext(ModalContext);

  const classes = useStyles();

  const { addItem } = React.useContext(CartItemsContext);
  const { dispensary } = React.useContext(DispensaryContext);

  const [showStatusHelp, setShowStatusHelp] = React.useState(false);
  const [showAddedCheck, setShowAddedCheck] = React.useState();

  const handleAddToCart = (id, product, quantity) => {
    setShowAddedCheck(true);
    addItem(id, product, quantity);
  };

  const orderDate = new Date(created_at * 1000).toDateString();
  const { date:fulfillmentDate, hours:timeslot} = fulfillment_time_slot;

  let deliveryDate = '';
  if (fulfillmentDate.year) {
    const { year, month, day } = fulfillmentDate;
    const { start_hour } = timeslot;
    deliveryDate = new Date(year, month - 1, day, start_hour, 0, 0, 0).toDateString();
  }
  let deliveryWindow = '';
  if (timeslot)  {
    deliveryWindow = getDeliveryWindowLabel(timeslot);
  }

  // on-site versus pre-paid
  const paymentLabel = getPaymentLabel(payment_details);

  // older Oct 2020 test orders have no costs data
  const totalCost = costs.total ? parseFloat(costs.total/100).toFixed(2) : 'XXX.XX';

  const cancelStyle = status === 'canceled' ? styles.canceled : '';
  return ( dispensary?.id
    ? <div className={`${styles.orderDetails} ${cancelStyle}`}>
        <div className={styles.orderDetailHdr}>
          <div className={`${styles.orderDetailHdrItem} ${styles.col1}`}>
            <div className={styles.orderDetailHdrLbl}>
              Order Placed
            </div>
            <div className={`${styles.orderDetailHdrVal} ${classes.orderDetailHdrVal}`}>
              {orderDate}
            </div>
          </div>

          <div className={`${styles.orderDetailHdrItem} ${styles.col2}`}>
            <div className={styles.orderDetailHdrLbl}>
              Total
            </div>
            <div className={`${styles.orderDetailHdrVal} ${classes.orderDetailHdrVal} ${styles.orderTotal}`}>
              ${totalCost}
            </div>
          </div>

          <div className={`${styles.orderDetailHdrItem} ${styles.col3}`}>
            <div className={styles.orderDetailHdrLbl}>
              Dispensary
            </div>
            <div className={`${styles.orderDetailHdrVal} ${classes.orderDetailHdrVal}`}>
              <span className={styles.link} onClick={() => {
                navigateTop(`/`);
              }}>
                {dispensary.name}
              </span>
            </div>
          </div>

          <div className={`${styles.orderDetailHdrItem} ${styles.col4}`}>
            <div className={styles.orderDetailHdrLbl}>
              Order Id
            </div>
            <div className={`${styles.orderDetailHdrVal} ${classes.orderDetailHdrVal} ${styles.orderId}`}>
              {id}
            </div>
          </div>

          <div className={`${styles.orderDetailHdrItem} ${styles.col1} ${styles.statusRow}`}>
            <div className={styles.orderDetailHdrLbl}>
              Order Type
            </div>
            <div className={`${styles.orderDetailHdrVal} ${classes.orderDetailHdrVal}`}>
              {type}
              <div className={styles.paymentLabel}>{paymentLabel}</div>
            </div>
          </div>

          <div className={`${styles.orderDetailHdrItem} ${styles.col2} ${styles.statusRow}`}>
            <div className={styles.orderDetailHdrLbl}>
              Delivery Window
            </div>
            <div className={`${styles.orderDetailHdrVal} ${classes.orderDetailHdrVal} ${styles.deliveryWindow}`}>
              { deliveryDate &&
                <>{deliveryDate}</>
              }
              { deliveryWindow &&
                <div>{deliveryWindow}</div>
              }
            </div>
          </div>

          <div className={`${styles.orderDetailHdrItem} ${styles.col3} ${styles.statusRow}`}>
            <div className={styles.orderDetailHdrLbl}>
              Order Status
            </div>
            <div className={`${styles.orderDetailHdrVal} ${classes.orderDetailHdrVal}`}>
              <span className={styles.status}>
                {statusForDisplay(status)}
              </span>{' '}
              <HelpRounded style={{
                cursor:'pointer',
                verticalAlign:'text-bottom',
                fontSize: 20
              }}
              onClick={() => setShowStatusHelp(true)} />
            </div>
          </div>

          <div className={`${styles.orderDetailHdrItem} ${styles.col4} ${styles.statusRow}`}>
            { enableReOrder
              ? <Button text="Reorder" withStyle={{padding:'.75rem 1.75rem'}} handleClick={()=>{
                  navigateTop(`/reOrder/${id}`);
                  trackEvent('reorder_from_orders_click');
                }} />
              : canceling
                ? <div>
                    {/* CancelOrderButton spinner not rendering in this context */}
                    <SpinnerWrapper showSpinner={true} />
                  </div>
                : <CancelOrderButton
                    orderId={id}
                    orderStatus={status}
                    dispensaryId={dispensary_id}
                    onCancelStart={()=> setCanceling(true)}
                    onOrderCanceled={() => {
                      setCanceling(false);
                      refetchOrders();
                    }} />
            }
          </div>
        </div>

        <div className={styles.orderedProducts}>
          <div className={styles.orderItemsHeader}>Products</div>
          <div className={styles.orderItems}>
            { items.length &&
              items.map(item => (
                <div key={`${id}-${item.id}`} className={styles.orderItem}>
                  <ProductCardForGrid
                    Id={item.id}
                    product={item}
                    orderQuantity={item.quantity_requested}
                    styleModule={STYLE_MODULE.ORDER_ITEM}
                    handleDetailClick={() => {
                      trackEvent('orders_product_card_click');
                      displayModal(ModalType.PRODUCT, {
                        itemId: item.id,
                        title: item.display_info.name,
                        categoryName: item.display_info.category.display_name,
                        relatedProducts: items,
                        relatedProductsCarouselTitle: "Also in this Order",
                      })
                    }}
                    handleCartClick={(quantity)=>handleAddToCart(item.id, item, quantity)} />
                </div>
              ))
            }
          </div>
        </div>

        { showStatusHelp &&
          <DisplayModal title="Order Statuses" closeFn={() => setShowStatusHelp(false)}>
            <OrderStatusHelp orderType={type} />
          </DisplayModal>
        }
        { showAddedCheck &&
          <AddedToCartCheck
            key={`chk${dispensary_id}`}
            closeFn={() => {
              setShowAddedCheck(false);
            }}
          />
        }
      </div>
    : <Spinner />
  )
}

OrderDetails.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  created_at: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  dispensary_id: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  costs: PropTypes.object.isRequired,
  payment_details: PropTypes.object,
  fulfillment_time_slot: PropTypes.object.isRequired,
  enableReOrder: PropTypes.bool,
  canceling: PropTypes.bool,
  setCanceling: PropTypes.func,
  refetchOrders: PropTypes.func.isRequired,
};

export default OrderDetails;
