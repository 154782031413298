import * as React from "react";

import config from "../../config";
import SearchLocationAutoComplete from "../googlemaps/SearchLocationAutoComplete";
import BasicButton from '../styleguide/BasicButton';
import LocationOnRoundedIcon from '@material-ui/icons/LocationOnRounded';
import PropTypes from 'prop-types';

import '../registration/MaterialUI.module.css';
import styles from './AddressSearch.module.css';

/**
 * Provide a form to edit the location-search selected address ( minus apartment number )
 */
const AddressUpdaterInline = ({
  location,
  editAddress,
  setEditAddress,
  onUpdate
}) => {

  // Updated user entries from AddressForm
  const [streetAddress, setStreetAddress] = React.useState();
  const [city, setCity] = React.useState();
  const [state, setState] = React.useState();
  const [zipCode, setZipCode] = React.useState();
  const [geoCoords, setGeoCoords] = React.useState();

  // Set display values (non-edit mode)
  React.useEffect(() => {
    const {
      street_address: currentStreet,
      city: currentCity,
      state: currentState,
      zip_code: currentZipCode,
      geo_coordinates: currentCoords
    } = location;
    setStreetAddress(currentStreet);
    setCity(currentCity);
    setState(currentState);
    setZipCode(currentZipCode);
    setGeoCoords(currentCoords);
  }, [location]);

  // e.g. update the provider
  const onAddressUpdate = (location) => {
    const {
      address: street_address,
      city,
      stateShort: state,
      zipCode: zip_code,
      geo_coordinates,
    } = location;

    onUpdate({
      street_address,
      city,
      state,
      zip_code,
      geo_coordinates,
    });
    setEditAddress(false);
  }

  return (
    <div className={styles.addressForm}>
      { editAddress
        ? <div className={styles.formWrap}>
            <div className={`${styles.addressWithEditButton} ${styles.inputWrap}`}>
              <div className={styles.pinIcon}>
                <LocationOnRoundedIcon fontSize={'inherit'} style={{color:'#0000008a'}} />
              </div>
              <SearchLocationAutoComplete onSelection={onAddressUpdate} />
              <div style={{textAlign:'right'}}>
                <BasicButton
                  text="Cancel"
                  withStyle={{position:'static', marginTop:12}}
                  handleClick={() => setEditAddress(false)} />
              </div>
            </div>
          </div>
        : <div className={styles.addressWithEditButton}>
            <div className={styles.formattedAddress}>
              {`${streetAddress}, ${city}, ${state} ${zipCode}`}
            </div>
            <div className={styles.fauxLabel}>
              Address
            </div>
            <BasicButton
              text="Edit"
              handleClick={() => setEditAddress(true)} />
            { config.IS_DEV &&
              <div style={{padding:'2px 0', color:'#a5a5a5', fontSize:'12px'}}>
                DEBUG: Lat: {geoCoords?.latitude} Long: {geoCoords?.longitude}
              </div>
            }
          </div>
      }
    </div>
  );
}

AddressUpdaterInline.propTypes = {
  location: PropTypes.object.isRequired,
  editAddress: PropTypes.bool,
  setEditAddress: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired
}

export default AddressUpdaterInline;
