import React from 'react';

import config from '../../config';
import { navigateTopWithState } from '../routing/router-utils';
import { updateUserInfo } from '../registration/registration-utils';
import { SIGN_IN_TEXT } from '../registration/SignIn';
import BodyWrapper, { LayoutTypes } from '../layout/BodyWrapper';
import YourAccountHeader from './YourAccountHeader';
import useCustomerOrders from '../orders/useCustomerOrders';
import ActiveOrdersWarning from '../orders/ActiveOrdersWarning';
import ReferralLinkForm from '../refer/ReferralLinkForm';
import CustomerCreditProvider from '../refer/CustomerCreditProvider';
import DisplayCreditBalance from '../refer/DisplayCreditBalance';
import DisplayInvalidCredits from '../refer/DisplayInvalidCredits';
import ChangePasswordForm from './ChangePasswordForm';
import DiscreetToggleForm from './DiscreetToggleForm';
import AddressForm from './AddressForm';
// import VerifyEmailForm from './VerifyEmailForm';
import PhoneNumberForm from './PhoneNumberForm';
import PhotoIDForm from './PhotoIDForm';
import { AeropayContext } from '../payment/AeropayProvider';
import PaymentFormAerosync from './PaymentFormAerosync';
import SubscribeForm from './SubscribeForm';
import { UserContext } from '../providers/UserProvider';
import { useAnalyticsStore } from '../../App';
import Header, { HeaderSize } from '../styleguide/Header';
import BackLink from '../common/BackLink';
import Spinner from '../common/Spinner';
import ErrorMessage from '../styleguide/ErrorMessage';
import SuccessMessage from '../styleguide/SuccessMessage';
import Button from '../styleguide/Button';
import PageTitle from '../common/PageTitle';
import { PropTypes } from 'prop-types';

import styles from './YourAccount.module.css';

const YourAccount = ({location}) => {

  const { trackEvent, trackError } = useAnalyticsStore();

  // To cancel XHR requests on unmount
  const updateAbortController = new AbortController();

  const noUpdateMsg = ('');

  // Address updated
  const addressRef = React.useRef();
  const [updateMsg, setUpdateMsg] = React.useState(noUpdateMsg);

  // Phone updated
  const mobileRef = React.useRef();
  const [mobileUpdateMsg, setMobileUpdateMsg] = React.useState(noUpdateMsg);

  // Id image updated
  const photoIdRef = React.useRef();
  const [photoIdUpdateMsg, setPhotoIdUpdateMsg] = React.useState(noUpdateMsg);

  // Subscribe updated
  // Now handled by the SubscribeForm conponent

  // Discreet delivery updated
  const discreetRef = React.useRef();
  const [discreetMsg, setDiscreetMsg] = React.useState(noUpdateMsg);

  const { user,
          userName,
          userEmail,
          userPhone,
          location: address,
          emailOptIn,
          textOptIn,
          deliveryTextOptIn,
          discreetEnabled,
          refetchUser,
          userError } = React.useContext(UserContext);

  const { activeOrders } = useCustomerOrders(user);

  const { aeropayEnabled } = React.useContext(AeropayContext);

  // Send the update data
  const handleSubmit = (payload, sectionRef, msgHandler) => {
    if (sectionRef.current) {
      sectionRef.current.classList.add(styles.faded);
    }
    msgHandler(noUpdateMsg);
    updateUserInfo(payload, (responseOk, response) => { updateCallback(responseOk, response, sectionRef, msgHandler) }, updateAbortController);
  };

  // Handle the response: status === 204 or error in response
  const updateCallback = (responseOk, response, sectionRef, msgHandler) => {
    // status === 204
    if (responseOk) {
      msgHandler(<SuccessMessage isInline text="Updated!" />);
      trackEvent('account_user_update_success');
      refetchUser();
    } else {
      const error = response.error || 'Unable to update your account';
      msgHandler(<ErrorMessage isInline text={error} />);
      trackError('account_user_update_error');
    }
    // unfade the section
    if (sectionRef.current) {
      sectionRef.current.classList.remove(styles.faded);
    }
  };

  // Optional back link
  const [backLink, setBackLink] = React.useState();
  React.useEffect(() => {
    const { returnToPath } = location?.state || {};
    if (returnToPath && !returnToPath.indexOf('account') > -1) {
      setBackLink(returnToPath);
    }
  }, [location.state])

  const AccountItem = ({title, children}) => (
    <div className={`${styles.accountSection} ${styles.withForm}`}>
      <div className={styles.accountLbl}>{title}</div>
      <div className={styles.sectionForm}>
        {children}
      </div>
    </div>
  );

  // TODO: Migrate to this?
  const AccountItemWithForm = ({title, children}) => (
    <>
      <div className={styles.accountLbl}>{title}</div>
      <div className={styles.sectionForm}>
        {children}
      </div>
    </>
  );

  return (
    <BodyWrapper pageLayout={LayoutTypes.Narrow}>
      <PageTitle title="Your Account" />
      <CustomerCreditProvider>
        <div className={styles.yourAccountWrap}>
          {/* if we have a userName we have a registered user */}
          { userName
            ? <>
                <Header isCentered HeaderSize={HeaderSize.Large}>
                  { backLink &&
                    <BackLink inHeader backToPath={backLink} />
                  }
                  <>Your Account</>
                </Header>

                {/* Account updates are disabled when orders are active */}
                { activeOrders?.length > 0 &&
                  <ActiveOrdersWarning />
                }

                <div className={styles.formWrap}>
                  <YourAccountHeader fullName={userName} email={userEmail} user={user}
                    refetch={refetchUser} />

                  { config.ENABLE_CREDITS && user && !user.isAnonymous &&
                    <>
                      <AccountItem title="Refer">
                        <ReferralLinkForm />
                      </AccountItem>
                      <AccountItem title="Credits">
                        <DisplayCreditBalance  />
                        <DisplayInvalidCredits />
                      </AccountItem>
                    </>
                  }

                  <AccountItem title="Deals Texts">
                    <div className={styles.withoutForm}>Not receiving our discounts and deals texts? Text <b>JOIN</b> to:
                    {' '}<a className={styles.smsNumber} href="sms:+12702419427">(270) 241-9427</a>
                    </div>
                  </AccountItem>

                  <div className={`${styles.accountSection} ${styles.accountPassword}`}>
                    <div className={styles.accountLbl}>
                      Password
                    </div>
                    <ChangePasswordForm userEmail={userEmail} />
                  </div>

                  <div ref={discreetRef} className={`${styles.accountSection} ${styles.withForm}`}>
                    <AccountItemWithForm title="DISCREET">
                      <DiscreetToggleForm
                        discreetEnabled={discreetEnabled}
                        handleSubmit={handleSubmit}
                        sectionRef={discreetRef}
                        msgHandler={setDiscreetMsg}
                        updateMsg={discreetMsg} />
                    </AccountItemWithForm>
                  </div>

                  { address &&
                    <div ref={addressRef} className={`${styles.accountSection} ${styles.withForm}`}>
                      <div className={styles.accountLbl}>Address</div>
                      <div className={styles.sectionForm}>
                        <AddressForm
                          location={address}
                          handleSubmit={handleSubmit}
                          sectionRef={addressRef}
                          msgHandler={setUpdateMsg}
                          updateMsg={updateMsg}
                        />
                      </div>
                    </div>
                  }

                  {/* Save Email Verification in case we need it
                    <div className={`${styles.accountSection} ${styles.withForm}`}>
                      <div className={styles.accountLbl}>Email</div>
                      <div className={styles.sectionForm}>
                        <VerifyEmailForm email={userEmail} />
                      </div>
                    </div>
                  */}

                  { userPhone &&
                    <div ref={mobileRef} className={`${styles.accountSection} ${styles.withForm}`}>
                      <div className={styles.accountLbl}>Mobile</div>
                      <div className={styles.sectionForm}>
                        <PhoneNumberForm
                          tel_number={userPhone}
                          handleSubmit={handleSubmit}
                          sectionRef={mobileRef}
                          msgHandler={setMobileUpdateMsg}
                          updateMsg={mobileUpdateMsg} />
                      </div>
                    </div>
                  }

                  { aeropayEnabled &&
                    <AccountItem title="Payment">
                      <PaymentFormAerosync />
                    </AccountItem>
                  }

                  { userEmail && config.ENABLE_ID_UPLOAD &&
                    <div ref={photoIdRef} className={`${styles.accountSection} ${styles.withForm}`}>
                      <div className={styles.accountLbl}>
                        ID Image
                      </div>
                      <div className={styles.sectionForm}>
                      <PhotoIDForm
                        email={userEmail}
                        handleSubmit={handleSubmit}
                        sectionRef={photoIdRef}
                        msgHandler={setPhotoIdUpdateMsg}
                        updateMsg={photoIdUpdateMsg} />
                      </div>
                    </div>
                  }

                  <div className={`${styles.accountSection} ${styles.withForm}`}>
                    <div className={styles.accountLbl}>Subscribe</div>
                    <div className={styles.sectionForm}>
                      <SubscribeForm
                        emailEnabled={emailOptIn}
                        textEnabled={textOptIn}
                        deliveryTextEnabled={deliveryTextOptIn}
                      />
                    </div>
                  </div>

                </div>
              </>
            : user?.isAnonymous
              ? <div className={styles.signInWrap}>
                  <ErrorMessage isCentered text="You need to be signed in to access your account" />
                  <Button isCentered text={SIGN_IN_TEXT} handleClick={
                    () => navigateTopWithState('/signIn', {
                      continuePath: '/account'
                    })
                  } />
                </div>
              : userError
                ? <ErrorMessage isCentered text={userError} />
                : <Spinner />
          }
        </div>
      </CustomerCreditProvider>
    </BodyWrapper>
  )
}

YourAccount.propTypes = {
  location: PropTypes.object /* from router */
}

export default YourAccount
