import * as React from "react";

import config from "../../config";
import { auth } from '../../firebase';
import { onAuthStateChanged } from "firebase/auth";
import { AttributionContext } from "../providers/AttributionProvider";
import { GoogleMapsContext } from "../providers/GoogleMapsProvider";
import { trackEvent } from '../analytics/tracking';
import { UserContext } from '../providers/UserProvider';
import { SIGN_IN_TEXT } from "../registration/SignIn";
import SuppressNav from '../layout/SuppressNav';
import AddressSearch from './AddressSearch';
import Button from '../styleguide/Button';
import Spinner from "../common/Spinner";
import DisplayCurrentSlotETAOverSearch from "../timeSlots/DisplayCurrentSlotETAOverSearch";

import styles from './FramableLocationSearch.module.css';

/**
 * Home page address entry: Collect address and continue to app home page
 */
const FramableLocationSearch = ({disableDevStyles}) => {

  const { mapsAPILoaded } = React.useContext(GoogleMapsContext);

  // Is not iframed
  const isDev = window.top === window.self;
  // redirect=true to suppress over 21 modal on shop.zyprun.com
  const redirectParam = 'redirect=true';
  const navigationHost = isDev
              ? `${window.location.protocol}//${window.location.host}`
              : `${config.REACT_APP_APP_DOMAIN}`;

  // The provider will store/persist the provided address, but not in Safari...
  const { setSearchLocation } = React.useContext(UserContext);
  // So the address hash is better
  const [ addressHash, setAddressHash] = React.useState('');
  // The referrer messaged from the ZypRun host page
  const { referralDomain } = React.useContext(AttributionContext);

  // Style for non-iframe dev purposes
  if (isDev && !disableDevStyles) {
    document.body.classList.add('testMode');
  }

  // Pass referrer provided by host page
  const [referrerHash, setReferrerHash] = React.useState('');
  React.useEffect(() => {
    if (referralDomain) {
      setReferrerHash(`ref.${window.btoa(referralDomain)}`);
    }
  }, [referralDomain]);

  const navigateToApp = React.useCallback(() => {
    window.top.location.href = `${navigationHost}?${redirectParam}#${addressHash||'a.b'}+${referrerHash}`;
  }, [navigationHost, redirectParam, addressHash, referrerHash]);

  const navigateToAppSignIn = React.useCallback(() => {
    window.top.location.href = `${navigationHost}/signIn?${redirectParam}#${referrerHash}`;
  }, [navigationHost, redirectParam, referrerHash]);

  /* Record component render so we can calc the authed percentage of home page visitors */
  React.useEffect(() => {
    trackEvent('homepage_iframe_render');
  }, []);

  /* Skip address input for registered users */
  const [isRegistered, setIsRegistered] = React.useState();

  React.useEffect(() => {
    /* eslint-disable no-unused-vars */
    let stateChangeHandler = onAuthStateChanged(auth, () => {
      if (auth?.currentUser &&
          !auth.currentUser.isAnonymous) {
        setIsRegistered(true);
        /* track authed percentage of home page visitors */
        trackEvent('homepage_iframe_authed_user');
      }
    });
    return () => { stateChangeHandler = undefined };
  }, []);

  const btnStyle = {
    fontSize: '1.25rem',
  };

  return (
    <div className={styles.framedContentWrap}>
      <SuppressNav />
      { isRegistered
        ? <div className={styles.registeredUserMsg}>
            {/*<div className={styles.hdrLarge}></div>*/}
            <div className={styles.btnWrap}>
              <Button
                isDark
                withStyle={btnStyle}
                text="Shop Cannabis Delivery"
                handleClick={navigateToApp} />
            </div>
          </div>
        : <>
            <div className={styles.inputWrap}>
              <div className={styles.etaWrapper}>
                <DisplayCurrentSlotETAOverSearch />
              </div>
              {  mapsAPILoaded
                ? <AddressSearch
                    arrowOnly
                    onSubmit={navigateToApp}
                    onAddressSelect={(address) => {
                      // setting searchLocation may be enough to set address
                      setSearchLocation(address);
                      setAddressHash(`address.${window.btoa(JSON.stringify(address))}`);
                    }}
                    isWideDesign />
                : <Spinner color={'#000'} size={32} isInline inSmallLayout />
              }
              <div className={styles.minorDetail}>
                Already have an account?{' '}
                <span className={styles.appLink} onClick={navigateToAppSignIn}>
                  {SIGN_IN_TEXT}
                </span>
              </div>
            </div>
          </>
      }
    </div>
  );
}

export default FramableLocationSearch;
