import * as React from 'react';
import ModalAlert from '../modal/ModalAlert';
import { getDeliveryWindowLabel } from '../deliveryWindows/scheduled-delivery-utils';
import PropTypes from 'prop-types';

const SlotChangeModalAlert = ({
  open,
  setOpen,
  timeSlots,
  slotIdx,
  styles,
}) => (
  timeSlots[slotIdx]
    ? <ModalAlert
        open={open}
        setOpen={setOpen}>
        <>
          <div>Your delivery window has been updated to:</div>
          <div className={styles.newWindow}>{getDeliveryWindowLabel(timeSlots[slotIdx].hours)}</div>
          <div>
            You can select any available delivery window, but your previous
            selection is no longer available.
          </div>
        </>
      </ModalAlert>
    : null
)

SlotChangeModalAlert.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
  timeSlots: PropTypes.array,
  slotIdx: PropTypes.number,
  styles: PropTypes.object.isRequired,
}

export default SlotChangeModalAlert;
