import * as React from 'react';

import { navigateTopWithState } from '../routing/router-utils';
import { trackEvent } from '../analytics/tracking';
import { UserContext } from '../providers/UserProvider';
import { CartItemsContext } from '../checkout/CartItemsProvider';
import { hideHeaderReferralCalloutKey, storeValue, getStoredValue } from '../util/storage-utils';
import { CREDIT_DETAILS } from './GetReferCreditDetails';
import styles from '../common/PageHeader.module.css';

import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';

const ReferCalloutInHeader = () => {

  const { currentLocation } = React.useContext(UserContext);
  const { cartItems } = React.useContext(CartItemsContext);
  const [ showCallout, setShowCallout ] = React.useState();

  const hideCallout = () => {
    setShowCallout(false);
    trackEvent('refer_hdr_callout_hide');
    // We may want to re-enable the callout so we'll store the dismiss Date
    const dismissDateMS = new Date().getTime();
    storeValue(hideHeaderReferralCalloutKey, dismissDateMS);
  }

  const referClick = React.useCallback(() => {
    trackEvent('refer_hdr_callout_clk');
    navigateTopWithState('/refer', {
      returnToPath: `${window.location.pathname}`
    });
  }, [])

  React.useEffect(() => {
    const closedByUser = getStoredValue(hideHeaderReferralCalloutKey);
    setShowCallout(
      currentLocation &&  // Because Promo covers address prompt
      !cartItems.length &&
      !closedByUser
    );
  }, [currentLocation, cartItems])

  return (showCallout
      ? <div className={styles.referFriendsOverlay}>
          Give ${CREDIT_DETAILS.total},Get ${CREDIT_DETAILS.total}:
          <span onClick={referClick} className={styles.calloutReferLink}>Refer your Friends!</span>
          <span className={styles.referFriendsClose} onClick={hideCallout}>
            <HighlightOffRoundedIcon style={{fontSize:'25px'}} />
          </span>
        </div>
      : null
  );
};

export default ReferCalloutInHeader;
