import * as React from 'react';

import { UserContext } from '../providers/UserProvider';
import { navigateTop } from '../routing/router-utils';
import useCustomerOrders from './useCustomerOrders';
import PageTitle from '../common/PageTitle';
import BodyWrapper, { LayoutTypes } from '../layout/BodyWrapper';
import Header, { HeaderSize } from '../styleguide/Header';
import OnfleetDeliveryMap from './OnfleetDeliveryMap';
import OrderDetails from './OrderDetails';
import Spinner from '../common/Spinner';

import styles from './YourOrders.module.css';

/**
 * We're currently showing most recent 10 ( API returns 100 )
 */
const YourOrders = () => {

  const { user } = React.useContext(UserContext);

  const {
    customerOrders,
    activeOrders,
    priorOrders,
    refetch,
    loading
  } = useCustomerOrders(user);

  // If we don't have a registered user session after 3s, redirect to home
  let redirectAnonymous = React.useRef();
  React.useEffect(() => {
    window.clearTimeout(redirectAnonymous.current);
    redirectAnonymous.current = window.setTimeout(() => {
      if(!user || user.isAnonymous) {
        navigateTop(`/`);
      }
    }, 3000);
  }, [user]);

  // Cancel spinner state needs to be maintained here
  const [canceling, setCanceling] = React.useState();

  const OrderSection = ({label, orders, isActiveOrders, canceling, setCanceling}) => {
    return orders?.length > 0
      ? <>
          <div className={styles.orderSectionHdr}>{label}</div>
          { orders.map((order) => {
              return <OrderDetails
                key={order.id}
                enableReOrder={!isActiveOrders}
                refetchOrders={refetch}
                canceling={canceling}
                setCanceling={setCanceling}
                {...order} />
            })
          }
        </>
      : null
  };

  return (
    <BodyWrapper pageLayout={LayoutTypes.Narrow}>
      <PageTitle title="Your Orders" />
      <div className={styles.orderWrap}>
        <Header HeaderSize={HeaderSize.Large} isCentered text="Your Orders">
          <span className={styles.headerNote}>(12 most recent)</span>
        </Header>
        {/* TODO/remove: activeOrders?.length > 0 &&
          <Alert severity='info' style={{margin: '1rem 0 1.5rem'}}>
            <b>Order Status Tip:</b> Orders displaying an "En Route" or "In Transit" status are NEXT
            for delivery and the driver is currently headed to your address. Orders displaying a "Bagged"
            or "Ready" status, are in many cases already in our vehicle and "out for delivery"
            &mdash;we just don't have a way to give you that update (yet!).
          </Alert>
        */}
        { !loading && customerOrders?.length >= 0
            ? customerOrders.length === 0
              ? <div className={styles.noOrdersMsg}>
                  <p>Hey, looks like you haven't placed any orders...</p>
                  <p>Maybe it's time to
                    {' '}<span className={styles.shopLink} onClick={() => {
                      navigateTop(`/`)}}>
                      Shop Cannabis Products!
                    </span>
                  </p>
                </div>
              : <>
                  <OnfleetDeliveryMap />
                  <OrderSection label="Active Orders" orders={activeOrders}
                                canceling={canceling} setCanceling={setCanceling}
                                isActiveOrders />
                  <OrderSection label="Previous Orders" orders={priorOrders} />
                </>
            : <Spinner />
        }
      </div>
    </BodyWrapper>
  )
}

export default YourOrders;
