import * as React from 'react';

import { makeStyles } from '@material-ui/core';
import { ZR_PRIMARY_DARK } from '../../constants/colors';
import BagIcon from '../checkout/BagIcon';
import Message from '@material-ui/icons/Message';
import Home from '@material-ui/icons/Home';
import DirectionsCar from '@material-ui/icons/DirectionsCar';

const useStyles = makeStyles({
  updateItems: {
    margin: '1rem 0 0'
  },
  updateItem: {
    display: 'flex',
    lineHeight: '133%',
    '& svg': {
      marginTop: '1rem'
    },
    '& p': {
      marginLeft: '.75rem'
    }
  },
  contact: {
    paddingLeft: 38,
    fontSize: '.875rem',
  },
  contactStrong: {
    whiteSpace: 'nowrap',
    fontWeight: 700,
  },
  phone: {
    color: 'var(--zrPrimaryDark)'
  }
});

const WhatToExpect = () => {

  const classes = useStyles();

  const iconStyle = {
    verticalAlign: 'middle',
    marginTop: '1rem',
    fontSize:24,
    color: 'var(--zrPrimaryDark)'
  }

  return (
    <>
      <ul className={classes.updateItems}>
        <li className={classes.updateItem}>
          <div><BagIcon fillColor={ZR_PRIMARY_DARK} size="24px" /></div>
          <p>Your products will be prepared and packed at our warehouse.</p>
        </li>

        <li className={classes.updateItem}>
          <DirectionsCar style={iconStyle} />
          <p>Our driver will receive your products and head to your residence.</p>
        </li>

        <li className={classes.updateItem}>
          <Message style={iconStyle} />
          <p>You'll receive a text 5 minutes before we arrive.</p>
        </li>

        <li className={classes.updateItem}>
          <Home style={iconStyle} />
          <p>Our driver will meet you at your door.</p>
        </li>
      </ul>
      <div className={classes.contact}>
        <span className={classes.contactStrong}>Questions?</span>
        {' '}Use the{' '}
        <span className={classes.contactStrong}>Chat icon</span>
        {' '}or call us at{' '}
        <a className={`${classes.contactStrong} ${classes.phone}`} href="tel:+16174050429">(617) 405-0429</a>
      </div>
    </>
  )
}

export default WhatToExpect;
