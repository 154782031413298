import * as React from 'react';
import closeX from '../../assets/closeX.png';

/**
 * Just a close modal X icon, styled.
 * Required styles:
 *   .closeWrapper
 *   .closeIcon
 */
const CloseModalIcon = ({
  styles,
  height=24,
  setOpen
}) => (
  <span className={styles.closeWrapper} onClick={() => setOpen(false)}>
    <img alt="Close Modal" className={styles.closeIcon} src={closeX} height={height} />
  </span>
)

export default CloseModalIcon;
