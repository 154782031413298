import * as React from 'react';

import { navigateTop } from '../routing/router-utils';
import { trackEvent } from '../analytics/tracking';
import { ModalContext, ModalType } from '../modal/ModalProvider';
import { CartItemsContext } from '../checkout/CartItemsProvider';
import CroppedProductImage  from './CroppedProductImage';
import LabResults, { TypeBackgroundColors } from './LabResults';
import { productLinkUsingSku } from '../util/util';
import BizOwnerTooltip from '../common/BizOwnerTooltip';
import LowStockWarning from './LowStockWarning';
import SoldOutRibbon from './SoldOutRibbon';
import AddToCartButton from './AddToCartButton';
import CloseModalButton from './CloseModalButton';
import SuggestedProductsCarousel from './SuggestedProductsCarousel';
import BundledProductsCarousel from './BundledProductsCarousel';
import ShareLink from '../common/ShareLink';
import PropTypes from 'prop-types';

import styles from './ProductDetailsCard.module.css';

/**
 *  This component displays the detailed product view in the modal popup
 *  and the single product SEO page
 */
const ProductDetailsCard = ({
    product,
    onAddToCart,
    cartItemOnRender,      // return from address prompt
    cartOnRenderQuantity,  // return from address prompt
    relatedProducts,
    relatedProductsCarouselTitle,
    isModal=true
  }) => {

  const {
    id: Id,
    productGrams,
    quantity_available,
    display_sold_out_status,
    // bundle_deal_data,
  } = product;

  const {
    name,
    brand,
    image_url,
    category,
    sub_category,
    description,
    lab_results,
    cannabis_type,
  } = product.display_info;

  const { addItem } = React.useContext(CartItemsContext);
  const { displayModal } = React.useContext(ModalContext);

  const [bgColor, setBgColor] = React.useState();
  React.useEffect(() => {
    setBgColor(
      TypeBackgroundColors[cannabis_type] || TypeBackgroundColors.DEFAULT
    );
  }, [Id, cannabis_type]);

  const formattedDesc = description
    ? description.replace(/\n/g, "<br />")
    : 'No Description';

  // Handle return from ADDRESS modal by carting product
  React.useEffect(() => {
    if (product && onAddToCart && cartItemOnRender && cartOnRenderQuantity) {
      addItem(product.id, product, cartOnRenderQuantity);
      onAddToCart();
    }
  }, [product, addItem, cartItemOnRender, cartOnRenderQuantity, onAddToCart])

  const brandClick = React.useCallback((isLowerLink) => {
    displayModal(ModalType.NONE);
    navigateTop(`/partner/${product.vendor_id}/${category.display_name}`);
    trackEvent(`partner_link_${isLowerLink ? 'lower_' : 'upper_'}clk`);
  },[product, category, displayModal])


  const bodyStyle = { backgroundColor: bgColor };

  return (
    <div key={`${Id}-modal`} className={styles.card}>
      <div className={styles.productImageWrap}>
        <div className={styles.productImageInner}>
          <CroppedProductImage
            productName={name}
            src={image_url}
            category={category.display_name}
            isModal />
          <div className={styles.cartButtonDesktop}>
            <AddToCartButton
              product={product}
              onAddToCart={onAddToCart} />
          </div>
        </div>
        <SoldOutRibbon
          quantity_available={quantity_available}
          display_sold_out_status={display_sold_out_status} />
      </div>

      <div className={`${styles.productBody} ${isModal ? '' : styles.nonModal}`} style={bodyStyle}>
        <div className={styles.productName}>{name}</div>
        <div className={styles.brandGrid}>
          { brand &&
            <div className={styles.brand}>
              <span className={styles.brandLink} onClick={() => brandClick(false)}>{brand}</span>
              <BizOwnerTooltip
                businessName={brand}
                inModal />
            </div>
          }
          <LowStockWarning
            quantityAvailable={quantity_available}
            display_sold_out_status={display_sold_out_status} />
        </div>

        <div className={styles.productDesc}>
          <div dangerouslySetInnerHTML={{ __html:formattedDesc }}></div>
        </div>

        <div className={styles.productInfo}>
          <LabResults
            productId={Id}
            lab_results={lab_results}
            cannabisType={cannabis_type}
            category={category}
            subCategory={sub_category}
            grams={productGrams} />
        </div>

        <div>
          <ShareLink
            ctaText='Share this product'
            shareText={`Zyp Run Delivery: Get ${name} delivered to your door in a Zyp!`}
            shareLink={productLinkUsingSku(product.dutchie_sku)} />
        </div>
        <div className={styles.fullBrandLink}>
          <span className={styles.brandLink} onClick={() => brandClick(true)}>See All {brand} Products</span>
        </div>

        { relatedProducts?.length > 0
          ? product.bundle_deal_data?.id
            ? <BundledProductsCarousel
                currentProductId={Id}
                bundleId={product.bundle_deal_data?.id}
                bundleMinQuantity={product.bundle_deal_data.minimum_quantity}
                carouselTitle={relatedProductsCarouselTitle || sub_category?.display_name}
                relatedProducts={relatedProducts} />
            : <SuggestedProductsCarousel
                currentProductId={Id}
                carouselTitle={relatedProductsCarouselTitle || sub_category?.display_name}
                relatedProducts={relatedProducts} />
          : <></>
        }
        { isModal &&
          <CloseModalButton />
        }
      </div>
      <div className={`${styles.cartButtonMobile} ${isModal ? '' : styles.nonModal }`}>
        <AddToCartButton
          product={product}
          onAddToCart={onAddToCart}
          isMobile />
      </div>
    </div>
  )
}

ProductDetailsCard.propTypes = {
  product: PropTypes.object.isRequired,
  onAddToCart: PropTypes.func,
  cartItemOnRender: PropTypes.bool,       /* cart item when returning from address prompt */
  cartOnRenderQuantity: PropTypes.number,  /* cart item when returning from address prompt */
  relatedProducts: PropTypes.array,
  relatedProductsCarouselTitle: PropTypes.string,
  isModal: PropTypes.bool,
};

export default ProductDetailsCard;
