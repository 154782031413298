import * as React from 'react';

import { devLog } from '../util/util';
import { trackEvent } from '../analytics/tracking';
import { UserContext } from '../providers/UserProvider';
import { DispensaryContext } from '../dispensary/DispensaryProvider';
import { DeliveryOptionsContext } from '../providers/DeliveryOptionsProvider';
import { CurrentTimeContext } from './CurrentTimeProvider';
import { getFormattedHourLabel, getFormattedTimeLabel } from '../deliveryWindows/scheduled-delivery-utils';
import { getMaxDaysOut, getNextAvailableSlot,
         getSlotsStartingToday, getLatestOrderTimeMS } from './timeslot-utils';

// No same-day 2 hour windows available
export const NO_SAME_DAY_SLOTS_IDX = -1;

// Priority Delivery config
export const PRIORITY_ROUNDING_MINUTES = 5; // Use 1 for no rounding
export const PRIORITY_UPDATE_MINUTES = 5; // Update display every X minutes
export const PRIORITY_DELIVERY_MINUTES = 60 * 60 * 1000; // 60 minutes

export const getPriorityDeliveryETA = (currentDate) => {
  // Adjust time to target delivery time
  currentDate.setTime(currentDate.getTime() + PRIORITY_DELIVERY_MINUTES);
  const end_hour = currentDate.getHours();
  const end_minute = Math.ceil(currentDate.getMinutes() / PRIORITY_ROUNDING_MINUTES) * PRIORITY_ROUNDING_MINUTES;
  // "2:45pm"
  return getFormattedTimeLabel(end_hour, end_minute);
};

const markExpiredSlots = (currentDaySlots, currentTimeMS) => {
  currentDaySlots.forEach(slot => {
    const slotCloseMS = getLatestOrderTimeMS(0, slot);
    slot.isExpired = slotCloseMS <= currentTimeMS;
  });
};

/**
 * This hook is currently only used at checkout by DisplayDeliverySlots
 * We update the timeSlotsByDay data every 5 minutes to keep the
 * options updated
 */
const useCurrentTimeSlots = () => {

  // Check for zip code exclusions on slots
  const { location } = React.useContext(UserContext);

  const { timeSlotsByDay, refetchSlots } = React.useContext(DeliveryOptionsContext);

  // Current time is only updated every minute or so
  const currentTimeMS = React.useContext(CurrentTimeContext);

  // Priority delivery configs (by zip code)
  const { priorityDeliveryConfigs, getTodaysBusinessHours } = React.useContext(DispensaryContext);

  // Default time slot, -1 when unset
  const [defaultSlot, setDefaultSlot] = React.useState(NO_SAME_DAY_SLOTS_IDX);
  const [defaultDay, setDefaultDay] = React.useState(0);
  // Slots for display (given the day of week + days out)
  const [displayTimeSlots, setDisplayTimeSlots] = React.useState();
  // This is a rerender trigger for the periodic slot update: refreshCurrentSlots
  const [refreshCount, setRefreshCount] = React.useState(0);


  // The time after which we need to refresh the UI
  const staleTimeMS = React.useRef(0);

  /**
   * TODO: NOT ENABLED CURRENTLY
   *
   * This will update slot discounts/availability
   * The slot discount is not recalcing to reflect slot updates
   */
  const refetchCurrentSlots = React.useCallback(() => {
    refetchSlots();
    setRefreshCount(refreshCount + 1);
  }, [refetchSlots, refreshCount])

  /**
   * Determine the valid/available delivery time slots based on currentTimeMS
   */
  React.useEffect(() => {
    // NOTE: Wait for location as priority fees need to be calced
    if (currentTimeMS && timeSlotsByDay && location) {
      // Update the time of last refresh
      staleTimeMS.current = currentTimeMS;

      const { zip_code } = location || {};

      // e.g. 2 for Tuesday
      const todayNum = new Date(currentTimeMS).getDay();
      const weeklySlotsStartingToday = getSlotsStartingToday(todayNum, timeSlotsByDay);

      // add .isExpired attribute to expired slots
      markExpiredSlots(weeklySlotsStartingToday[0], currentTimeMS);

      // Display only bookable days
      const maxDaysOut = getMaxDaysOut(weeklySlotsStartingToday, currentTimeMS);
      let validSlots =  weeklySlotsStartingToday.slice(0, maxDaysOut);

      // Get first available slot or -1, updated later based on priority enabled/disabled
      let [defaultDayIdx, defaultSlotIdx] = getNextAvailableSlot(validSlots, currentTimeMS, zip_code);

      // Priority delivery settings for zip code
      const zipCodePriorityConfig = priorityDeliveryConfigs?.find(config => config.zip_code === zip_code);
      // [ start_hour, end_hour ]
      const businessHours = getTodaysBusinessHours() || [0,0];
      const currentHour = new Date(currentTimeMS).getHours();

      /**
       * Create Priority Delivery Slot during biz hours, even if disabled ATM
       */
      if ( zipCodePriorityConfig &&
           currentHour >= businessHours[0] &&
           currentHour < businessHours[1]
      ) {

        // Remove existing priority slot
        validSlots[0] = validSlots[0].filter(slot => !slot.on_demand);
        const prioritySlot =  {
          start_hour : 0,    // unused?
          start_minute : 0,  // unused?
          isExpired: !zipCodePriorityConfig.is_enabled,
        };

        const currentDate = new Date(currentTimeMS);
        currentDate.setTime(currentDate.getTime() + PRIORITY_DELIVERY_MINUTES);
        const end_hour = currentDate.getHours();
        const end_minute =  Math.ceil(currentDate.getMinutes() / PRIORITY_ROUNDING_MINUTES) * PRIORITY_ROUNDING_MINUTES;

        prioritySlot.on_demand = { ...zipCodePriorityConfig }

        prioritySlot.hours = {
          end_hour,
          end_minute,
        };
        /**
         * We want the priority slot to be the default, so we
         * insert it at the defaultSlotIdx position.
         */
        // Splice! Insert the priority slot at the current default slot index
        if (defaultDayIdx === 0 && defaultSlotIdx > NO_SAME_DAY_SLOTS_IDX) {
          // Insert at defaultSlotIdx position!
          validSlots[0].splice(defaultSlotIdx, 0, prioritySlot);
          // If priority is disabled/expired bump to next slot
          if (prioritySlot.isExpired) {
            defaultSlotIdx = defaultSlotIdx + 1;
          }
        } else {
          // There are no current-day 2 hour slots, so just append priority slot
          validSlots[0].push(prioritySlot);
          // Leave defaultSlotIdx at -1 if priority slot is disabled
          if (!prioritySlot.isExpired) {
            defaultSlotIdx = 0;
            // Revert to 0/Today when we have only a priority slot for same-day
            defaultDayIdx = 0;
          }
        }
        trackEvent('1hr_delivery_available');
        trackEvent(`1hr_delivery_${getFormattedHourLabel(end_hour)}_shown`);
      }

      // Adjusted based on Priority enabled/disabled
      setDefaultSlot(defaultSlotIdx);
      setDefaultDay(defaultDayIdx);
      setDisplayTimeSlots(validSlots);

      devLog('*** UPDATE AVAILABLE SLOT DISPLAY! ***');
    }
  }, [currentTimeMS, timeSlotsByDay, refreshCount,
      priorityDeliveryConfigs, getTodaysBusinessHours, location]);

  return {
    /* Provide slots for display */
    defaultSlot,
    defaultDay,
    displayTimeSlots,
    refetchCurrentSlots,
  }
};

export default useCurrentTimeSlots;
