import * as React from "react";

import PropTypes from 'prop-types';

const DisplayCartedBundleProducts = ({
  bundleDealData,
}) => {

  return ( bundleDealData?.size
    ? <table style={{marginTop:6, width:'100%'}}>
        <tbody>
          <tr>
            <th style={{textAlign:'left'}}>Bulk Deal:</th>
            <th style={{textAlign:'center'}}>Your Cart</th>
            <th style={{textAlign:'center'}}>Max</th>
          </tr>
          { Array.from(bundleDealData.entries()).map(([key, data]) => (
            <tr key={key}>
              <td style={{textAlign:'left'}}>{key}</td>
              <td style={{textAlign:'center'}}>{data.quantity}</td>
              <td style={{textAlign:'center'}}>{data.minimum - 1}</td>
            </tr>
          ))}
        </tbody>
      </table>
    : null
  );
};

export const bundleDealShape = {
  quantity: PropTypes.number,
  minimum: PropTypes.number,
}

DisplayCartedBundleProducts.propTypes = {
  bundleDealData: PropTypes.objectOf(PropTypes.shape(bundleDealShape)),
}

export default DisplayCartedBundleProducts;
