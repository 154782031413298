import config from '../../config';
import { devLog } from '../util/util';
import { auth } from '../../firebase';
import { trackError, trackEvent } from '../analytics/tracking';
import { newOrderEndpoint, retreiveOrdersEndpoint, validateOrderEndpoint, customerCartEndpoint } from '../../constants/api-endpoints';
import { DefaultImages } from '../products/product-utils';


// Order type field/param value
export const ORDER_TYPE_DELIVERY = 'delivery';

export const PAYMENT_LABELS = {
  'on-site-cash': 'Cash (on delivery)',
  'on-site-debit': 'Debit Card (on delivery)',
  'aeropay': 'Aeropay (pre-paid)'
};

export const getPaymentLabel = (payment_details) => {
  return PAYMENT_LABELS[payment_details.processor] || 'N/A';
};

// Handle Priority versus Standard Slot details
const addSlotDetails = (timeSlot, orderDto) => {
  // .on_demand is a useCurrentTimeSlots mutation
  if (timeSlot.on_demand) {
    const { zip_code, fee_cents, delivery_in_minutes } = timeSlot.on_demand;
    orderDto.on_demand = {
      zip_code,
      fee_cents,
      delivery_in_minutes,
    };
  } else {
    // standard slot
    orderDto.fulfillment_time_slot = timeSlot;
  }
  return orderDto;
}

/**
 * Validate and order prior to displaying the AeroPay payment popup
 *
 * (Prevent charging for invalid orders )
 *
 * @param {array} items
 * @param {object} timeSlot
 * @param {object} paymentDetails
 * @param {string} discount_code
 * @param {object} credits
 * @param {function} validateOrderCallback
 * @param {AbortController} controller
 */
export const validateDispensaryOrder = async (
  items,
  timeSlot,
  paymentMethod,
  discount_code,
  credits,
  validateOrderCallback,
  controller) => {
  // Authentication has been checked prior to displaying /placeOrder
  if (!auth.currentUser) {
    return;
  }

  const dispensary_id = config.ZYP_RUN_DISPENSARY_ID;

  const dispensaryOrder = {
    dispensary_id,
    customer_id: auth.currentUser.uid,
    type: ORDER_TYPE_DELIVERY,
    payment_method: paymentMethod.processor, // aeropay, on-site-cash, on-site-debit
    items: getItemDetails(items)
  };

  addSlotDetails(timeSlot, dispensaryOrder);

  // Credit trumps discount_code
  if (credits?.value.usa_cents) {
    dispensaryOrder.credits = {
      id: credits.id,
      usa_cents: credits.value.usa_cents
    };
  } else if (discount_code) {
    dispensaryOrder.discount_code = discount_code;
  }

  const validateOrderURL = validateOrderEndpoint.replace('[dispId]', dispensary_id);

  auth.currentUser.getIdToken(/* no need to force refresh */ false).then(idToken => {
    fetch(validateOrderURL, {
      method: 'POST',
      headers: {
        'Authorization': idToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(dispensaryOrder),
      signal: controller.signal
    }).then(response => response.json())
      .then(body => {
        validateOrderCallback(body);
      })
      .catch(error => {
        validateOrderCallback({ error: error.message });
      });
  });
};

/**
 * Place an order
 *
 * Delivery orders are validated prior to the payment flow and will not be submitted
 * TODO: Implement this for pickup.
 *
 * @param {array} items
 * @param {object} fulfillment_time_slot
 * @param {object} payment_details
 * @param {string} discount_code
 * @param {function} placeOrderCallback - continue to /orderConfirmation view
 * @param {AbortController} controller
 */
export const submitDispensaryOrder = async (
  items,
  fulfillment_time_slot,
  payment_details,
  discount_code,
  credits,
  attributions,
  customer_delivery_notes,
  placeOrderCallback,
  controller) => {

  // Authentication has been checked prior to displaying /placeOrder
  if (!auth.currentUser) {
    return;
  }

  const dispensary_id = config.ZYP_RUN_DISPENSARY_ID;

  const dispensaryOrder = {
    dispensary_id,
    type: ORDER_TYPE_DELIVERY,
    customer_id: auth.currentUser.uid,
    items: getItemDetails(items),
    payment_details,
  };

  addSlotDetails(fulfillment_time_slot, dispensaryOrder);

  // Cannot be empty or undefined
  if (customer_delivery_notes) {
    dispensaryOrder.customer_delivery_notes = customer_delivery_notes;
  }

  // Credit trumps discount_code
  if (credits?.value.usa_cents) {
    dispensaryOrder.credits = {
      id: credits.id,
      usa_cents: credits.value.usa_cents
    };
  } else if (discount_code) {
    dispensaryOrder.discount_code = discount_code;
  }

  // The partner domain might be google in which
  // case the partner_id will be undefined.
  const { partner_domain, partner_id } = attributions;
  if (partner_domain) {
    dispensaryOrder.attributions = {
      partner_domain,
      partner_id
    };
  }

  const dispensaryOrderURL = `${newOrderEndpoint}${dispensary_id}`;

  auth.currentUser.getIdToken(/* no need to force refresh */ false).then(idToken => {
    devLog('PLACING ORDER!');
    fetch(dispensaryOrderURL, {
      method: 'POST',
      headers: {
        'Authorization': idToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(dispensaryOrder),
      signal: controller.signal
    }).then(response => response.json())
      .then(body => {
        if (body.error) {
          placeOrderCallback({ error: body.error });
        } else {
          placeOrderCallback(body);
        }
      })
      .catch(error => {
        placeOrderCallback({ error: error.message });
      });
  });
};

/**
 * We need to verify that the order will succeed prior submitting
 * We need to make sure that
 * 1) Customer has no current day orders
 * 2) Product is still available
 * 3) Delivery Window is still valid/available
 * 4) The app-calculated price is the same/close to what was calculated by the API
 *
 * @param {object} response - the cost detail returned by the API order/validity endpoint
 * @param {function} callback - function handling the validation response
 */
 const validateOrderCallback = (response, totalPrice, onSuccess, setValidationErrorMessage) => {
  setValidationErrorMessage('');
  if (response.costs) {
    // get the diff in pennies
    const costDiff = parseInt(response.costs.total, 10) - parseInt(totalPrice, 10);
    // allow $1 wiggle room.
    if (costDiff < 101) {
      onSuccess();
      if (costDiff !== 0) {
        if (costDiff > -101) {
          trackError('order_minor_pricing_error');
        } else {
          // API cost more than $1 cheaper than display cost (customer win)
          trackError('order_major_pricing_error_favorable');
        }
      }
    } else {
      setValidationErrorMessage(
        `Sorry, there was a pricing problem with your order:
         your cart total is off by $${parseFloat(costDiff/100).toFixed(2)}.
         Try reloading the page and resubmitting your order or contact us via
         the Chat icon`
      );
      trackError('order_major_pricing_error');
    }
  } else {
    // Show server error: Item not available etc.
    // Error logging handled in component
    setValidationErrorMessage(response.error);
  }
};

export const validateAndPlaceOrder = (
  addedItems,
  timeSlot,
  paymentMethod,
  discount_code,
  credits,
  totalPrice,
  onSuccess,
  setValidationErrorMessage,
  controller) => {
    validateDispensaryOrder(
      addedItems,
      timeSlot,
      paymentMethod,
      discount_code,
      credits,
      (response) => {
        validateOrderCallback(response, totalPrice, onSuccess, setValidationErrorMessage)
      },
      controller
    );
};

/**
 * Retrieve orders for a user
 *
 * @param dispensaryId - the dispensary receiving the order
 * @param getOrdersCallback - the function that handles the response
 * @param controller - the abortController the component will use to cancel request on unmount (unlikely here)
 */
export const getCustomerOrders = async (getOrdersCallback, controller) => {
  if (auth.currentUser && !auth.currentUser.isAnonymous) {
    const dispensaryOrderURL = `${retreiveOrdersEndpoint}/${auth.currentUser.uid}`;
    auth.currentUser.getIdToken(/* no need to force refresh */ false).then(idToken => {
      fetch(dispensaryOrderURL, {
        method: 'GET',
        headers: {
          'Authorization': idToken,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        signal: controller.signal
      }).then(response => response.json())
        .then(body => {
          getOrdersCallback(body);
        })
        .catch(error => {
          getOrdersCallback({ error: error.message });
        });
    });
  }
};

/**
 * TOTAL PRICE CALCULATIONS
 * Computed based on API-provided tax/fee/delivery amounts
 */

/**
 * Compare order cost calculations front end versus back end
 * This is mostly to prevent off-by-one rounding issues, and ensure the
 * displayed total matches the billed total.
 *
 * Everything is rounded up: 10.1 -> 11.
 *
 * Any differences with be logged as an error.
 *
 * @param {object} frontEnd - the cost components used to display the total price
 * @param {object} backEnd - the cost components returned by the API
 */
export const validateCostCalculation = (frontEnd, backEnd, orderType) => {
  devLog(`validating costs for order type: ${orderType}`);
  devLog(`front: ${JSON.stringify(frontEnd)}`);
  devLog(`back: ${JSON.stringify(backEnd)}`);

  // values we don't need to validate
  const IGNORE_VALUES = [];

  const diffs = [];
  if (backEnd.total && frontEnd.total) {
    // Validate API costs first
    Object.keys(backEnd).forEach((key) => {
      if (!IGNORE_VALUES.includes(key)) {
        if (typeof frontEnd[key] === "undefined") {
          diffs.push(`Front end missing cost item: ${key}`);
        // Math.ceil(null) === 0 ftw
        } else if (Math.ceil(backEnd[key]) !== frontEnd[key]) {
          diffs.push(`Front end: ${key} off by ${Math.ceil(frontEnd[key] - Math.ceil(backEnd[key]))} cent(s)`);
        }
      }
    });
    // Check for unused front-end cost fields
    Object.keys(frontEnd).forEach((key) => {
      if (typeof backEnd[key] === "undefined") {
        diffs.push(`Back end missing cost item: ${key}`);
      }
    });
  }
  return diffs;
};

// debounce cart save/clear requests
let cartUpdateTask;
const cartUpdateDelayMS = 1000;

/**
 * Retrieve a user's cart items from previous session
 * ( DeBounced )
 *
 * @param cartInfo - cart item details: { order_type, dispensary_id, items }
 * @param controller - the abortController the component will use to cancel request on unmount (unlikely here)
 */
export const saveCartItems = async (cartInfo, controller) => {
  // Authentication has been checked
  if (!auth.currentUser) {
    return;
  }
  cartInfo.customer_id = auth.currentUser.uid

  window.clearTimeout(cartUpdateTask);
  cartUpdateTask = window.setTimeout(() => {
    auth.currentUser.getIdToken(/* no need to force refresh */ false).then(idToken => {
      fetch(customerCartEndpoint, {
        method: 'POST',
        headers: {
          'Authorization': idToken,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(cartInfo),
        signal: controller.signal
      }).then(response => {
        if (response.status === 200) {
          trackEvent(`cart_saved_items`);
        } else {
          response.json().then(body => {
            trackError(`error_saving_cart_${response.status}`);
          });
        }
      })
      .catch(error => {
        trackError('error_saving_cart');
      });
    })
  }, cartUpdateDelayMS);
};

/**
 * Clear a user's cart items - when they've removed everything
 *
 * @param controller - the abortController the component will use to cancel request on unmount (unlikely here)
 */
 export const clearSavedCartItems = async (controller) => {
  window.clearTimeout(cartUpdateTask);
  cartUpdateTask = window.setTimeout(() => {
    // Authentication has been checked prior to displaying /yourOrders
    if (!auth.currentUser) {
      return;
    }

    auth.currentUser.getIdToken(/* no need to force refresh */ false).then(idToken => {
      fetch(customerCartEndpoint, {
        method: 'DELETE',
        headers: {
          'Authorization': idToken,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        signal: controller.signal
      }).then(response => {
        if (response.status === 204) {
          // OK --- cart cleared
          trackEvent(`cart_cleared_items`);
        } else {
          trackError(`error_clearing_cart_${response.status}`);
        }
      }).catch(error => {
        trackError(`error_clearing_cart`);
      });
    });
  }, cartUpdateDelayMS);
};

// Extract only what's needed by the API for processing
export const getItemDetails = (items) => {
  return items.map(itm => ({ product_id: itm.id, quantity: itm.quantity }));
}

// Extract what's needed by the API for cart saving
export const getSaveCartItemDetails = (items) => {
  return items.map(item => {
    const { display_name:categoryName } = item.display_info.category;
    const imgUrl = item.display_info.image_url || DefaultImages[categoryName];
    return {
      product_id: item.id,
      quantity: item.quantity,
      sku: item.dutchie_sku,
      category_display_name: categoryName,
      sub_category_display_name: item.display_info.sub_category?.display_name || 'none',
      name: item.display_info.name,
      brand: item.display_info.brand,
      image_url: imgUrl,
      cannabis_type: item.display_info.cannabis_type || 'none',
    }
  });
}
