import * as React from "react";

import { UserContext } from '../providers/UserProvider';
import { useAnalyticsStore } from '../../App';
import SearchLocationAutoComplete from "../googlemaps/SearchLocationAutoComplete";
import SearchLocationInput from "../googlemaps/SearchLocationInput";
import LocationOnRoundedIcon from '@material-ui/icons/LocationOnRounded';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import PropTypes from 'prop-types';

import '../registration/MaterialUI.module.css';
import styles from './Address.module.css';

/**
 * Provide a form to edit the location-search selected address ( minus apartment number )
 *
 * NOTE: custom "buttonText" is fragile since the ellipsis handling requires
 * input padding equal to the width of the text (e.g. 8rem for "Get Started")
 */
const AddressSearch = ({
  buttonText="Get Started",
  arrowOnly,
  onAddressSelect,
  onSubmit,  /* determines whether we have submit button */
  placeholder="Enter your a​ddress", /* zero-width space required in "Address" to suppress Chrome autofill */
  trackingLabel="form",
  useLegacyInput
}) => {

  const { trackEvent, trackError } = useAnalyticsStore();

  const { setSearchLocation } = React.useContext(UserContext);

  const [currentAddress, setCurrentAddress] = React.useState();

  const handleAddressSelection = (response) => {
    if (response.address) {
      const {
        address: street_address,
        city,
        stateShort: state,
        zipCode: zip_code,
        geo_coordinates,
      } = response;

      const address = {
        street_address,
        city,
        state,
        zip_code,
        geo_coordinates,
      };
      trackEvent(`address_search_${trackingLabel}_select`);

      // Update UserProvider
      setSearchLocation(address);
      // Update local state
      setCurrentAddress(address);
      if (onAddressSelect) {
        onAddressSelect(address);
      }

    } else {
      trackError(`address_search_${trackingLabel}_error`);
    }
  };

  const handleSubmit = () => {
    trackEvent(`address_search_${trackingLabel}_submit`);
    // e.g. continue to home page
    if (onSubmit) {
      onSubmit();
    }
  };

  // Adjust right-side padding for ellipsis based on whether we have a submit button/arrow
  const submitStyle = onSubmit ? styles.withSubmit : '';

  const arrowStyle = arrowOnly ? styles.arrowOnly : '';

  const buttonClass = currentAddress ? styles.enabledButton : styles.disabledButton;

  return (
    <div className={`${styles.inputWrap} ${arrowStyle} ${submitStyle}`}>
      <div className={styles.pinIcon}>
        <LocationOnRoundedIcon fontSize={'inherit'} style={{color:'#000'}} />
      </div>
      { useLegacyInput
        ? <SearchLocationInput
            placeholder={placeholder} /* zero-width space required in "Address" to suppress Chrome autofill */
            onSelection={handleAddressSelection} />
        : <SearchLocationAutoComplete
            placeholder={placeholder} /* zero-width space required in "Address" to suppress Chrome autofill */
            onSelection={handleAddressSelection} />
      }
      {/* Show submit button only if we need it to navigate etc. */}
      { onSubmit &&
        <button className={`${styles.continueButton} ${buttonClass}`}
                onClick={currentAddress ? handleSubmit : ()=>{}}>
          { arrowOnly
            ? <ArrowForwardRoundedIcon fontSize={'inherit'} style={{verticalAlign:'-8px'}}/>
            : <span className={styles.buttonText}>{buttonText}</span>
          }
        </button>
      }
    </div>
  );
}

AddressSearch.propTypes = {
  buttonText: PropTypes.string,
  arrowOnly: PropTypes.bool,
  onAddressSelect: PropTypes.func,
  onSubmit: PropTypes.func,
  placeholder: PropTypes.string,
  trackingLabel: PropTypes.string,
  useLegacyInput: PropTypes.bool,
}

export default AddressSearch;
