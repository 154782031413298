import { isSuperDeal } from "../products/useSaleLabelDetails";

// The CartItemsProvider processing should be migrated to this reducer
// for simpler state updates
const useCartItemsReducer = (cartItems, bundlePricingMap) => {
  let bulkItems, superDealItems, flowerGrams=0;
  if (cartItems?.length) {
    if (bundlePricingMap) {
      bulkItems = cartItems.filter(item => bundlePricingMap?.has(item.bundle_deal_data?.id));
    }
    superDealItems = cartItems.filter(item => isSuperDeal(item))
    superDealItems = superDealItems.length ? superDealItems : undefined;

    flowerGrams = cartItems.reduce((total, item) => {
      return total + (item.quantity * (item.flower_equivalent_grams || 0))
    }, 0);
  }

  return {
    bulkItems,  // Does not account for Mix/Match
    superDealItems,
    flowerGrams, // FlowerEquivalentGrams
  }
}

export default useCartItemsReducer;
