import React from 'react';

import { UserContext } from '../providers/UserProvider';
import { DispensaryContext } from '../dispensary/DispensaryProvider';
import { dollarDisplay } from '../providers/OrderPricingProvider';
import { getFormattedTimeLabel } from '../deliveryWindows/scheduled-delivery-utils';
import StrongText from '../common/StrongText';
import PropTypes from 'prop-types';

import styles from './SelectTimeSlot.module.css';

const PriorityTimeSlot = ({
  idx,
  radioId,
  slot,
  isDisabled,
  isChecked,
  currentTimeMS,
  onSelect
}) => {

  // Currently rounded to 5 minutes
  const [displayETA, setDisplayETA] = React.useState('');
  React.useEffect(() => {
    const currentDate = new Date(currentTimeMS);
    currentDate.setTime(currentDate.getTime() + (1 * 60 * 60 * 1000));
    const hour = currentDate.getHours();
    const minute =  Math.ceil(currentDate.getMinutes() / 5) * 5;
    setDisplayETA(getFormattedTimeLabel(hour,minute));
  }, [currentTimeMS])

  const { location } = React.useContext(UserContext);
  const { freeDeliveryZipCodes } = React.useContext(DispensaryContext);

  // Suppress standard $3 fee messaging
  const [isFreeDeliveryZone, setIsFreeDeliveryZone] = React.useState();
  React.useEffect(() => {
    setIsFreeDeliveryZone(freeDeliveryZipCodes?.includes(location?.zip_code))
  }, [location, freeDeliveryZipCodes])

  const selectedStyle = isChecked ? styles.selected : '';
  const disabledStyle = isDisabled ? styles.disabledFldSet : '';

  return (
    <>
      <fieldset key={radioId}
        className={`${styles.fldSet} ${styles.prioritySlot} ${selectedStyle} ${disabledStyle}`}
        data-is-expired={isDisabled}>
        <input type="radio" name="timeSlot" id={radioId} value={idx}
               onChange={onSelect} checked={isChecked}
               disabled={isDisabled} />
        <label htmlFor={radioId}>
          { isDisabled
            ? <>Priority</>
            : <>Priority - by {displayETA}!*</>
          }
          { isChecked
            ? <div className={styles.prioritySelected}>SELECTED</div>
            : isDisabled
              ? <span className={styles.notAvailable}>
                  Not Currently Available
                </span>
              : <div className={styles.priorityChooseBtn}>CHOOSE</div>
          }
        </label>
      </fieldset>
      <div className={styles.priorityNote}>
        { slot.on_demand.fee_cents && !isDisabled
          ? <>
              *Priority Delivery:<StrongText>{dollarDisplay(slot.on_demand.fee_cents)}</StrongText>
              { isFreeDeliveryZone
                ? null
                : <span className={styles.priorityDisclaimer}>
                    (Standard 2-Hour Delivery:{' '}<b>$3.00</b>)
                  </span>
              }
            </>
          : null
        }
      </div>
  </>
  )
}

PriorityTimeSlot.propTypes = {
  idx: PropTypes.number.isRequired,
  radioId: PropTypes.string.isRequired,
  slot: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool,
  isChecked: PropTypes.bool.isRequired,
  currentTimeMS: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired
};

export default PriorityTimeSlot;
