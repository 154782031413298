import * as React from "react";

import StrongText from "../common/StrongText";
import useMixMatchBulkCartItems from "./useMixMatchBulkCartItems";
import { CartItemsContext } from "../checkout/CartItemsProvider";
import DisplayCartedBundleProducts from "../deals/DisplayCartedBundleProducts";
import Alert from "@material-ui/lab/Alert";
import PropTypes from 'prop-types';

// Adjust messaging depending on code/credit context
export const DISCOUNT_CODE_WARNING = (<><StrongText noWrap noMargin>Promo Codes</StrongText> (and Referral Credits)</>);
export const REFERRAL_CREDIT_WARNING = (<><StrongText noWrap noMargin>Referral Credits</StrongText> (and Promo Codes)</>);

// The idea here is that you can use a discountCode prop in the discount code context,
// and the activeCredit in the referral credit context.
const BulkCartWarning = ({
  discountCode,
  activeCredit,
  withClass
}) => {

  const { bulkItems } = React.useContext(CartItemsContext);
  // Consolidate Mix & Match deal item data
  const dealData = useMixMatchBulkCartItems(bulkItems);

  return ((discountCode || activeCredit) && dealData?.size
    ? <Alert severity="warning" className={withClass ? withClass : ''}>
        <div>
          { discountCode
              ? DISCOUNT_CODE_WARNING
              : REFERRAL_CREDIT_WARNING
          }
          {' '}cannot be used on orders that contain
          {' '}<StrongText noWrap noMargin>bulk-priced deals.</StrongText>
          {' '}To place your order, remove the
          { discountCode
              ? ' promo code'
              : ' referral credit'
          }
          {' '}OR reduce the quantity
          of your bulk-priced item(s) to the maximum listed below:
        </div>
        <DisplayCartedBundleProducts
          bundleDealData={dealData} />
      </Alert>
    : null
  );
};

BulkCartWarning.propTypes = {
  discountCode: PropTypes.string,
  activeCredit: PropTypes.object,
  withClass: PropTypes.string,
}

export default BulkCartWarning;
