import React from 'react';

import PropTypes from 'prop-types';
import styles from './ProductDetailsCard.module.css';

export const WARNING_THRESHHOLD = 10;

const LowStockWarning = ({
  quantityAvailable,
  display_sold_out_status,
}) => {

  return ( quantityAvailable < WARNING_THRESHHOLD
    ? display_sold_out_status && quantityAvailable === 1
      ? null
      : <div className={styles.lowStockWarning}>
          { quantityAvailable === 0
            ? `Sold out!`
            : `Only ${quantityAvailable} left!`
          }
        </div>
    : null
  )
}

LowStockWarning.propTypes = {
  quantityAvailable: PropTypes.number.isRequired,
  display_sold_out_status: PropTypes.bool
}

export default LowStockWarning;
