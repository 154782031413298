import * as React from 'react';

import useConfiguredAutoComplete from './useConfiguredAutoComplete';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';

// Deprecated, generates dupe places requests
const SearchLocationInput = ({
  /* Careful! there is a zero-width space within "address" to prevent Chrome auto-complete */
  placeholder='Enter your a​ddress',
  onSelection=()=>{},
  disableAutoFocus
}) => {

  const inputRef = React.useRef();
  const [inputVal, setInputVal] = React.useState("");

  // NEW custom hook to prevent loading 2X etc.
  useConfiguredAutoComplete(
    inputRef,
    setInputVal,
    onSelection,
  );

  return (
    <TextField
      autoFocus={!disableAutoFocus}
      autoComplete='off'
      inputRef={inputRef}
      label={placeholder}
      placeholder={placeholder}
      variant="filled"
      onChange={event => setInputVal(event.target.value)}
      onKeyDown={event => {
        if (event.key === 'Enter') {
          setInputVal(event.target.value);
        }
      }}
      value={inputVal}
    />
  );
}

SearchLocationInput.propTypes = {
  placeholder: PropTypes.string,
  onSelection: PropTypes.func.isRequired,
  disableAutoFocus: PropTypes.bool
}

export default SearchLocationInput;
