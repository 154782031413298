import * as React from "react";

import { UserContext } from "../providers/UserProvider";
import AddressSearch from './AddressSearch';
import PropTypes from 'prop-types';

import styles from './AddressForm.module.css';

/**
 * Basic address typeahead which updates the UserProvider via setSearchLocation
 * ( This gives us an address to use in the reg flow )
 * */
const AddressForm = ({
  onAddressSelect,
  onSubmit,
  onSignIn,
  showLabel=true,
  placeholder /* zero-width space required in "Address" to suppress Chrome autofill */
}) => {

  const { user, searchLocation } = React.useContext(UserContext);

  return (
    <div className={styles.formWrap}>
      { showLabel &&
        <div className={styles.inputLabel}>
          { searchLocation?.zip_code
            ? <>Update Delivery Address</>
            : <>Enter Your Address</>
          }
        </div>
      }
      <div className={styles.addressInput}>
        <AddressSearch
          placeholder={placeholder}
          onAddressSelect={onAddressSelect}
          onSubmit={onSubmit} />
      </div>
      { user?.isAnonymous &&
        <div className={styles.signIn} onClick={onSignIn}>
          Log in for saved address
        </div>
      }
    </div>
  );
}

AddressForm.propTypes = {
  onAddressSelect: PropTypes.func.isRequired,
  onSubmit: PropTypes.func, /* only when using a submit button */
  onSignIn: PropTypes.func.isRequired,
  showLabel: PropTypes.bool,
  placeholder: PropTypes.string.isRequired,
};

export default AddressForm;
