import * as React from 'react';

import { CREDIT_LABELS } from './DisplayCreditDetails';
import { dollarDisplay } from '../providers/OrderPricingProvider';
import { OrderSection } from '../checkout/PlaceOrder';
import { DiscountCreditAndFeeContext } from '../providers/DiscountCreditAndFeeProvider';
import { CartItemsContext } from '../checkout/CartItemsProvider';
import useMixMatchBulkCartItems from '../bundles/useMixMatchBulkCartItems';
import CartedDealsAccordion from '../deals/CartedDealsAccordion';
import Button from '../styleguide/BasicButton';
import BulkCartWarning from '../bundles/BulkCartWarning';
import SuperDealCartWarning from '../deals/SuperDealCartWarning';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';

import styles from './ReferralCredit.module.css';

// (Re) Apply currently available credit
const ApplyCredit = ({activeCreditValueCents, restoreCredit}) => (
  activeCreditValueCents
    ? <div className={styles.applyCreditWrap}>
        <div>
          You have a
          <span className={styles.creditAmount}>
            {dollarDisplay(activeCreditValueCents, true)} Credit!
          </span>
        </div>
        <Button withClass={styles.applyCreditButton} text="Apply Credit" handleClick={restoreCredit} />
      </div>
  : null
)

const DisplayCreditAtCheckout = () => {

  const { activeCredit, activeCreditCount, activeCreditValueCents, removeCredit, restoreCredit } = React.useContext(DiscountCreditAndFeeContext);
  const { bulkItems, superDealItems } = React.useContext(CartItemsContext);
  const { reason, referral_name, referrer_name, value } = activeCredit || {};

  // Bundle deal data for promo code suppression
  // TODO: This hook is used in 4 places, could be moved out for common use.
  const bundleDealData = useMixMatchBulkCartItems(bulkItems);

  React.useEffect(() => {
    if (activeCredit && (superDealItems || bundleDealData)) {
      removeCredit();
    }
  }, [activeCredit, removeCredit, superDealItems, bundleDealData])

  return ( activeCreditCount > 0
    ? <OrderSection title="CREDITS">
        { superDealItems || bundleDealData // Credit auto-removed
          ? <CartedDealsAccordion
              bundleDealData={bundleDealData}
              superDealItems={superDealItems}
              isCredits />
          : activeCredit  // Display active credit details
            ? <div className={styles.credit}>
                <div className={styles.type}>
                  {CREDIT_LABELS[reason]}
                </div>
                <div className={styles.user}>
                  { reason === 'referrer'
                    ? referral_name /* you received from */
                    : referrer_name /* you referred and got from */
                  }
                </div>
                <div className={styles.amount}>-{dollarDisplay(value.usa_cents)}</div>
                <div className={styles.remove} onClick={removeCredit}>
                  Remove
                  <HighlightOffRoundedIcon className={styles.removeIcon} />
                </div>
              </div>
            : <ApplyCredit  // Allow (re)application of credit
                activeCreditValueCents={activeCreditValueCents}
                restoreCredit={restoreCredit} />
        }
        {/* Current logic likely prevents these from ever showing: applied credit required */}
        <div className={styles.warnings}>
          <BulkCartWarning
            activeCredit={activeCredit}
            withClass={styles.alert} />
          <SuperDealCartWarning
            activeCredit={activeCredit}
            withClass={styles.alert} />
        </div>
      </OrderSection>
    : null
  );
};

export default DisplayCreditAtCheckout;
